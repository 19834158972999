import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import ReactQuill, { Quill } from "react-quill";
import Delta from "quill-delta";
import { useHistory, useParams } from "react-router-dom";
import {
  deleteTempNotice,
  getEachNoticeBook,
  postNotice,
  postNotificationAll,
  postTempSave,
  postUploadFile,
  postUploadImage,
  putModifyNoticeBook,
  putTempNoticeEdit,
} from "../common/Action";
import { MainContext } from "../common/Context";
import { time1 } from "../common/Method";
import close from "../images/close.png";
// import stamp from "../images/stamp.png";
import { IMainContext } from "../interface/Interface";
import { formatStyle } from "../common/QuillStyle";
import { t } from "i18next";

const NoticeBook = (props: any) => {
  const context = useContext<IMainContext>(MainContext);
  const history = useHistory();
  const params = useParams();
  const [noticeTitle, setNoticeTitle] = useState<string>(time1(Date.now()));
  const [noticeBody, setNoticeBody] = useState<string>("");
  const fileRef = useRef<HTMLInputElement>(null);
  const [files, setFiles] = useState<any>([]);
  const [fileUrls, setFileUrls] = useState<string[]>([]);
  const [originalFileUrls, setOriginalFileUrls] = useState<any[]>([]);
  const [students, setStudents] = useState<any[]>([]);
  const [checkAll, setCheckAll] = useState<boolean>(true);
  const quillRef = useRef<any>(null);

  useEffect(() => {
    // 수정할 때 데이터 받아오기
    init();
  }, []);

  const init = async () => {
    if (props.state === "edit" && !props.temp) {
      setNoticeTitle(props.noticeData?.title);
      setNoticeBody(props.noticeData?.body);
      if (props.noticeData?.body !== "")
        context.handleStateChange("noticeBookBody", props.noticeData?.body);
      setStudents([...props.studentData]);
      setFileUrls(props.noticeData?.fileUrls);
      setOriginalFileUrls(props.noticeData?.fileUrls);
    } else if (props.state === "edit" && props.temp) {
      // 임시저장일 때
      const noticeDetailResult: any = await getEachNoticeBook(props.noticeId, true);
      let tempStudentData: any = [...context.students];
      for (let i in tempStudentData) {
        for (let k in noticeDetailResult.studentsList) {
          if (tempStudentData[i].code === noticeDetailResult.studentsList[k]) {
            tempStudentData[i].noticeCheck = true;
            tempStudentData[i].confirmNotice = false;
          }
        }
      }

      setNoticeTitle(noticeDetailResult?.title);
      setNoticeBody(noticeDetailResult?.body);
      if (noticeDetailResult?.body !== "")
        context.handleStateChange("noticeBookBody", noticeDetailResult?.body);
      setStudents(tempStudentData);
      setFileUrls(noticeDetailResult?.fileUrls);
      setOriginalFileUrls(noticeDetailResult?.fileUrls);
    } else {
      let temp: any = [...context.students];
      for (let i in temp) {
        temp[i].noticeCheck = true;
      }
      setStudents(temp);
    }
  };

  const handleFile = (e: any) => {
    const filesArr = Object.keys(e.target.files);
    if (filesArr.length !== 0) {
      let fileTemp = [...files];
      let fileUrlTemp = [...fileUrls];
      for (let i = 0; i < filesArr.length; i++) {
        if (e.target.files[i].type.split("/")[0] === "image") {
          fileTemp.push(e.target.files[i]);
          fileUrlTemp.push(URL.createObjectURL(e.target.files[i]));
        } else {
          // alert("이미지만 첨부해주세요!");
          alert(t(`Please only attach images`));
        }
      }

      setFiles(fileTemp);
      setFileUrls(fileUrlTemp);
    }
  };

  const handleDeleteFile = (data: any, index: number) => {
    const filterfile = files.filter((el: any, i: number) => i !== index);
    const filter = fileUrls.filter((el: any, i: number) => el !== data);
    const filterOriginalFiles = originalFileUrls.filter((el: any, i: number) => el !== data);
    setFiles(filterfile);
    setOriginalFileUrls(filterOriginalFiles);
    setFileUrls(filter);
  };

  const handleNoticeCheck = (student: any, index: number) => {
    let temp = [...students];
    temp[index].noticeCheck = !students[index].noticeCheck;
    setStudents(temp);
  };

  const calcSelectStudent = () => {
    let count = 0;
    for (let i in students) {
      if (students[i].noticeCheck) {
        count += 1;
      }
    }
    return count;
  };
  const imgHandler = async () => {
    const input: any = document.createElement("input");

    input.setAttribute("type", "file");
    input.setAttribute("accept", "image/*");
    document.body.appendChild(input);

    input.click();

    input.onchange = async () => {
      // console.log(input.files);

      // const [file]: any = input.files;

      // S3 Presigned URL로 업로드하고 image url 받아오기
      const formData = new FormData();
      formData.append("file", input.files[0]);
      const getUrl = await postUploadImage(formData, 400);

      // 현재 커서 위치에 이미지를 삽입하고 커서 위치를 +1 하기
      const range = quillRef.current.getEditorSelection();
      quillRef.current.getEditor().insertEmbed(range.index, "image", getUrl);
      quillRef.current.getEditor().setSelection(range.index + 1);
      // document.body.querySelector(":scope > input").remove();
    };
  };

  const quillModules = useMemo(
    () => ({
      // clipboard: {
      //   matchers: [
      //     [
      //       "img",
      //       function (node: any, delta: any) {
      //         return alert("이미지는 복사/붙여넣기 하실 수 없습니다.");
      //         // console.log("이미지발굔");
      //         // 1. node에서 blob이미지 추출한다
      //         // file data로 만든다
      //         // console.log(node, delta);
      //         // return delta.compose(new Delta().retain(delta.length()));
      //       },
      //     ],
      //   ],
      // },

      toolbar: {
        container: [
          [{ size: [false, "10px", "20px", "30px", "40px"] }],
          // ["bold", "italic", "underline", "strike", "link", "image"],
          ["bold", "italic", "underline", "strike", "link"],
          [{ color: [] }, { background: [] }],
          [{ list: "ordered" }, { list: "bullet" }],
          [{ indent: "-1" }, { indent: "+1" }],
        ],
        handlers: {
          image: imgHandler,
        },
      },
    }),
    [],
  );

  const handleSubmit = async (type: string) => {
    // 알림장 전송
    if (type === "save") {
      const confirm = window.confirm(
        // 학생들에게 알림장이 전송됩니다.
        t(`Students are sent a notification
      `),
      );
      if (confirm) {
        let tempUrls: string[] = [];
        if (files.length > 0) {
          for (let i in files) {
            const formData = new FormData();
            formData.append("file", files[i]);
            const getUrls: any = await postUploadFile(formData);
            tempUrls.push(getUrls);
          }
        }
        let checkArr = [];
        for (let i in students) {
          if (students[i].noticeCheck) {
            checkArr.push(students[i].code);
          }
        }
        const data = {
          classId: props.classId,
          title: noticeTitle,
          body: noticeBody,
          fileUrls: tempUrls,
          studentCodeList: checkArr,
          temp: false,
        };
        let result: any = await postNotice(data);
        if (result.acknowledged) {
          let pushArr: any = [];
          let filterData = students.filter(
            (el: any, i: number) => el.noticeCheck && el.deviceToken2,
          );
          filterData.map((item: any, i: number) => {
            if (item.deviceToken2) {
              pushArr.push({
                token: item.deviceToken2,
                notification: {
                  title: `${noticeTitle}🔖`,
                  body: t(`Check your notifications`), // "알림장을 확인해 주세요!",
                },
                data: {
                  type: "notice",
                  code: item.code,
                },
              });
            }
          });
          postNotificationAll(pushArr);
        }
        context.handleStateChange("noticeBookBody", "");
      } else {
        return;
      }
    }
    if (type === "tempSave") {
      // 알림장 임시저장
      let tempUrls: string[] = [];
      if (files.length > 0) {
        for (let i in files) {
          const formData = new FormData();
          formData.append("file", files[i]);
          const getUrls: any = await postUploadFile(formData);
          tempUrls.push(getUrls);
        }
      }
      let checkArr = [];
      for (let i in students) {
        if (students[i].noticeCheck) {
          checkArr.push(students[i].code);
        }
      }
      const data = {
        classId: props.classId,
        title: noticeTitle,
        body: noticeBody,
        fileUrls: tempUrls,
        studentCodeList: checkArr,
        temp: false,
        studentsList: checkArr,
      };
      let result: any = await postTempSave(data);
      context.handleStateChange("noticeBookBody", "");
    }

    props.onClose();
    props.init();
  };

  const handleEditNotice = async (): Promise<void> => {
    const confirm = window.confirm(
      // "기존의 알림장 내용을 읽었던 학생들이 일괄 '안읽음' 처리 되며, 모두에게 푸쉬 알람이 갑니다.",
      t(`Students who have read the existing notification will be bulk...`),
    );
    if (confirm) {
      let tempUrls: string[] = [];
      const filterFiles: any = files.filter((el: any, i: number) => typeof el != "string");

      if (filterFiles.length > 0) {
        for (let i in filterFiles) {
          const formData = new FormData();
          formData.append("file", filterFiles[i]);
          const getUrls: any = await postUploadImage(formData, 500);
          tempUrls.push(getUrls);
        }
      }
      const urls = [...tempUrls, ...originalFileUrls];
      let tempCode = [];
      for (let i in students) {
        if (students[i].noticeCheck) {
          tempCode.push(students[i].code);
        }
      }
      const modifyNoticeData = {
        id: props.noticeId,
        title: noticeTitle,
        body: noticeBody,
        fileUrls: urls,
        studentCodeList: tempCode,
      };
      const postResult: any = await putModifyNoticeBook(modifyNoticeData);
      if (postResult.acknowledged) {
        let pushArr: any = [];
        let filter = students.filter((el: any, i: number) => el.noticeCheck && el.deviceToken);
        filter.map((item: any, i: number) => {
          pushArr.push({
            token: item.deviceToken2,
            notification: {
              title: `${noticeTitle}🔖`,
              body: t(`Check your notifications`), // "알림장을 확인해 주세요!",
            },
            data: {
              type: "notice",
              code: item.code,
            },
          });
        });

        postNotificationAll(pushArr);
      }

      context.handleStateChange("noticeBookBody", "");
      history.goBack();
    } else {
      return;
    }
  };

  const handleCheckAll = () => {
    let temp: any = [...students];
    for (let i in temp) {
      temp[i].noticeCheck = checkAll ? false : true;
    }
    setCheckAll((prev: boolean) => !prev);
    setStudents(temp);
  };

  // 임시저장 수정시
  const handleTempEditNotice = async (): Promise<void> => {
    let tempUrls: string[] = [];
    const filterFiles: any = files.filter((el: any, i: number) => typeof el != "string");

    if (filterFiles.length > 0) {
      for (let i in filterFiles) {
        const formData = new FormData();
        formData.append("file", filterFiles[i]);
        const getUrls: any = await postUploadImage(formData, 500);
        tempUrls.push(getUrls);
      }
    }
    const urls = [...tempUrls, ...originalFileUrls];

    let tempCode = [];
    for (let i in students) {
      if (students[i].noticeCheck) {
        tempCode.push(students[i].code);
      }
    }

    const modifyTempNoticeData: any = {
      _id: props.noticeId,
      title: noticeTitle,
      body: noticeBody,
      fileUrls: urls,
      studentsList: tempCode,
    };

    const postResult: any = await putTempNoticeEdit(modifyTempNoticeData);

    if (postResult) {
      // alert("임시저장 알림장이 수정되었습니다.");
      alert(t(`Fixed the drafts notifier`));
    }

    props.onClose();
    context.handleStateChange("noticeBookBody", "");
    props.noticeDetailInit();
  };

  // 임시저장 -> 알림장 발송
  const handleTempToSave = async (): Promise<void> => {
    // console.log(fileUrls);
    const confirm = window.confirm(
      // "학생들에게 알림장이 전송됩니다."
      t(`Students are sent a notification`),
    );

    if (confirm) {
      let tempUrls: string[] = [...originalFileUrls];
      if (files.length > 0) {
        for (let i in files) {
          const formData = new FormData();
          formData.append("file", files[i]);
          const getUrls: any = await postUploadFile(formData);
          tempUrls.push(getUrls);
        }
      }

      let checkArr = [];
      for (let i in students) {
        if (students[i].noticeCheck) {
          checkArr.push(students[i].code);
        }
      }
      const data = {
        classId: props.classId,
        title: noticeTitle,
        body: noticeBody,
        fileUrls: tempUrls,
        studentCodeList: checkArr,
      };
      let result: any = await postNotice(data);
      let deleteTemp: any = await deleteTempNotice({
        _id: props.noticeId,
      });
      if (result.acknowledged) {
        let pushArr: any = [];
        let filterData = students.filter((el: any, i: number) => el.noticeCheck && el.deviceToken);
        filterData.map((item: any, i: number) => {
          pushArr.push({
            token: item.deviceToken2,
            notification: {
              title: `${noticeTitle}🔖`,
              body: t(`Check your notifications`), // "알림장을 확인해 주세요!",
            },
            data: {
              code: item.code,
              type: "notice",
            },
          });
        });
        postNotificationAll(pushArr);
      }
    } else {
      return;
    }
    props.onClose();
    history.goBack();
  };

  const onChangeQuill = (editor: any) => {
    const inputValue = editor.getHTML();
    // const regex = /<IMG src=\"([^\"]*?)\">/gi;

    // const imgArr = inputValue.match(regex);
    // console.log(imgArr);
    setNoticeBody(inputValue);
    if (inputValue !== "") context.handleStateChange("noticeBookBody", inputValue);
    // console.log(editor.getHTML());
    // console.log(editor.getContents());
  };

  return (
    <>
      <div className="notice-wrapper pl-10">
        <div className="notice-quill-container">
          <div>
            <div
              className="display-f justify-between"
              style={{ marginBottom: !props.temp && props.state === "edit" ? 15 : 0 }}
            >
              <div>
                <input
                  value={noticeTitle}
                  onChange={(e: any) => setNoticeTitle(e.target.value)}
                  className="notice-title"
                />
                <div style={{ marginTop: -5 }}>
                  <p className="text_gray">
                    {/* * 날짜 부분을 클릭하여 제목을 수정하실 수 있습니다 * */}
                    {t(`You can edit the title by clicking on the date`)}
                  </p>
                </div>
              </div>

              <div className={`text-right mb-10`}>
                <div className="mr-10 mb-5">
                  {/* 새로 생성일 때 */}
                  {props.state === "add" && (
                    <button
                      style={{
                        color: "#fff",
                        backgroundColor: "rgb(204, 153, 102)",
                      }}
                      onClick={() => handleSubmit("save")}
                      className="not-btn font-500"
                    >
                      {/* 전송하기 */}
                      {t(`Send`)}
                    </button>
                  )}
                </div>

                <div className="display-f justify-center align-center">
                  {/* 알림장 새로 생성일 때 */}
                  {props.state === "add" && !props.temp && (
                    <button
                      onClick={
                        props.state === "add" ? () => handleSubmit("tempSave") : handleEditNotice
                      }
                      className="not-btn-border mr-10 font-500"
                    >
                      {/* 임시저장 */}
                      {t(`Draft`)}
                    </button>
                  )}

                  <div>
                    {/* 임시저장 알림장을 전송할 때 */}
                    {props.state === "edit" && props.temp && (
                      <div className="text-right">
                        <button
                          style={{
                            color: "#fff",
                            backgroundColor: "rgb(204, 153, 102)",
                            fontWeight: 500,
                          }}
                          onClick={handleTempToSave}
                          className="not-btn mb-5"
                        >
                          {/* 전송하기 */}
                          {t(`Send`)}
                        </button>
                      </div>
                    )}

                    <button
                      onClick={() => {
                        if (props.state === "edit") {
                          history.goBack();
                          context.handleStateChange("noticeBookBody", "");
                        } else {
                          props.onClose();
                          context.handleStateChange("noticeBookBody", "");
                        }
                      }}
                      style={{ marginRight: !props.temp ? "10px" : "" }}
                      className="not-btn mr-10"
                    >
                      {/* 취소 */}
                      {t(`Cancel`)}
                    </button>
                    {/* 임시저장을 수정할 때 */}
                    {props.state === "edit" && props.temp && (
                      <button onClick={handleTempEditNotice} className="not-btn-border font-500">
                        {/* 수정 사항 임시 저장 */}
                        {t(`Draft`)}
                      </button>
                    )}
                  </div>

                  {/* 알림장을 수정할 때 */}
                  {props.state === "edit" && !props.temp && (
                    <button
                      style={{
                        color: "#fff",
                        backgroundColor: "rgb(204, 153, 102)",
                      }}
                      onClick={
                        props.state === "add" ? () => handleSubmit("save") : handleEditNotice
                      }
                      className="not-btn font-500"
                    >
                      {/* 알림장 재전송 */}
                      {t(`Resend notification letters`)}
                    </button>
                  )}
                </div>
              </div>
            </div>

            <div className="quill-wrapper">
              <ReactQuill
                ref={quillRef}
                value={noticeBody}
                // onChange={(e: any) => {
                //   setNoticeBody(e);
                // }}
                onChange={(content: any, delta: any, source: any, editor: any) =>
                  onChangeQuill(editor)
                }
                className="notice-quill"
                modules={quillModules}
                formats={formatStyle}
              />
            </div>

            <input
              multiple
              ref={fileRef}
              type="file"
              className="display-n"
              name="file"
              onChange={(e: any) => handleFile(e)}
            />
            <button
              onClick={() => {
                fileRef.current !== null && fileRef.current.click();
              }}
              className="focus add__file__btn font-13 font-500 mr-11 mt-10"
            >
              {/* 파일 첨부 */}
              {t(`Attach`)}
            </button>
          </div>

          <div className="display-f" style={{ flexWrap: "wrap", margin: "10px 0" }}>
            {fileUrls?.map((data: string, index: number) => (
              <div key={index} style={{ position: "relative" }}>
                <img src={data} className="img-add2 mb-10" alt="file" />
                <img
                  alt="file"
                  onClick={() => handleDeleteFile(data, index)}
                  src={close}
                  className="cursor"
                  style={{ width: 15, height: 15, position: "absolute", right: 15, top: 15 }}
                />
              </div>
            ))}
          </div>

          <div className="display-f receiver-container">
            <>
              <div>
                <div
                  className="focus add__file__btn font-13 font-500 mr-11 display-f justify-center align-center"
                  style={{ backgroundColor: "#cc9966", marginRight: 10, width: 180 }}
                >
                  <p className="white">
                    {/* "수신 학생 목록" */}
                    {t(`Recipient List`)} {`${calcSelectStudent()}/${students.length}`}
                  </p>
                </div>
                <div style={{ width: 150, margin: "5px 0 10px 0" }}>
                  <p style={{ fontSize: 12 }}>{t(`From the list, deselect the students to...`)} </p>
                  {/* added 240619 아래 주석처리 */}
                  {/* <p style={{ fontSize: 12 }}>리스트에서 삭제하세요.</p>  */}
                </div>
                <div className="display-f align-center" style={{ width: 150 }}>
                  <div style={{ marginRight: 5, marginBottom: 20 }}>
                    <div
                      onClick={handleCheckAll}
                      style={{
                        border: "1px solid #de4949",
                        padding: "5px 10px",
                        borderRadius: 4,
                        fontSize: 12,
                        color: "#de4949",
                        cursor: "pointer",
                      }}
                    >
                      <p>
                        {/* 모두 해제 : 모두 선택 */}
                        {checkAll ? t(`Deselect All`) : t(`Select All`)}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </>

            <div
              style={{
                width: "100%",
                height: "100%",
                display: "flex",
                flexWrap: "wrap",
              }}
            >
              {students
                .sort((a: any, b: any) => a.num - b.num)
                .map((student: any, index: number) => {
                  return (
                    <button
                      onClick={() => handleNoticeCheck(student, index)}
                      key={index}
                      className={`student-item ${
                        student.noticeCheck ? "yellow" : "bg-white-notice"
                      }`}
                    >
                      {student.name}
                    </button>
                  );
                })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NoticeBook;
