import axios from "axios";
import React, { useState, useEffect, useContext } from "react";
// import { useHistory } from "react-router-dom";
// import logoTop from "../images/logo-top.png";
// import logoBottom from "../images/logo-bottom.png";
import {
  getLinkToken,
  getUserInfo,
  postCancelMembership,
  postCancelMembershipKakao,
  postCreateLinkCode,
  // postSignup,
  // postUserEdit,
  putModifyUserInfo,
} from "../common/Action";
import { Redirect, useHistory } from "react-router-dom";
import { IMainContext } from "../interface/Interface";
import { MainContext } from "../common/Context";
import { t } from "i18next";

const AuthEdit: React.FC = () => {
  // const history = useHistory();
  const context = useContext<IMainContext>(MainContext);
  const [id, setId] = useState<string>("");
  // 연동 아이디
  const [linkCode, setLinkCode] = useState<string>("");
  const [name, setName] = useState<string>("");
  // const [currentPw, setCurrentPw] = useState<string>("");
  const [pw, setPw] = useState<string>("");
  const [pwCheck, setPwCheck] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [phone, setPhone] = useState<string>("");

  const history = useHistory();

  useEffect(() => {
    init();
  }, []);

  const init = async (): Promise<void> => {
    const user: any = await getUserInfo();

    if (!user.linkCode) {
      createLinkCode();
    } else {
      // const linkToken = await getLinkToken(user.linkCode);
      // 연동코드로 로그인가능한 토큰
      // console.log(linkToken);
      setLinkCode(user.linkCode);
    }

    setId(user.id);
    setName(user.name);
    setEmail(user.email);
    setPhone(user.phone);
  };

  const createLinkCode = async () => {
    const linkcode: any = await postCreateLinkCode({});
    if (linkcode) {
      init();
    }
  };

  // const handleCheckEmail = async (): Promise<void> => {
  //   if (email === "") return;
  //   const result = await getExistEmail(email);
  //   if (!result) {
  //     alert("사용 가능한 이메일입니다!");
  //   } else {
  //     alert("사용이 불가능한 이메일입니다. 관리자에게 문의해주세요.");
  //   }
  // };

  // const handleEmail = (e: any) => {
  //   e.preventDefault();
  //   setEmail(e.target.value);
  // };

  const handleSubmit = async () => {
    if (name === "") {
      // return alert("이름을 입력해주세요.");
      return alert(t(`Please enter a name`));
    }
    if (pw === "") {
      // return alert("비밀번호를 입력해주세요.");
      return alert(t(`Please enter a password`));
    }
    if (pw.length < 5) {
      // return alert("최소 6글자 이상의 비밀번호를 입력해주세요.");
      return alert(t(`Please enter a password with at least 6 characters`));
    }
    if (pw !== pwCheck) {
      // return alert("같은 비밀번호를 입력해주세요.");
      return alert(t(`Please enter the same password`));
    }
    if (phone === "") {
      // return alert("전화번호를 입력해주세요.");
      return alert(t(`Please enter your phone number`));
    }

    const result: any = await putModifyUserInfo({
      name: name,
      phone: phone,
      password: pw,
    });

    if (!result) {
      // alert("수정이 불가능합니다. 변경하시려는 정보를 확인해주세요.");
      alert(t(`Cannot be edited...`));
    } else {
      // alert("정보 수정이 완료되었습니다.");
      alert(t(`The information is corrected`));
      window.location.href = "/";
    }
    // history.goBack();
  };

  const verifyEmail = () => {
    if (email === "") return true;
    const str: RegExp =
      /^[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}$/i;
    return str.test(email);
  };

  const handleCancelMembership = async () => {
    const confirm = window.confirm(
      // "회원탈퇴 시 데이터 복구는 볼가힙니다. 탈퇴하시겠습니까? \n회원탈퇴 시 데이터 복구 및 결제하신 서비스의 환불은 볼가합니다. 탈퇴하시겠습니까?",
      t(`You cannot recover your data if you cancel your membership...`),
    );
    if (confirm) {
      if (context.userInfo.kakaoId) {
        await postCancelMembershipKakao();
      } else {
        await postCancelMembership();
      }

      // alert("회원탈퇴가 완료되었습니다.");
      alert(t(`Your membership withdrawal has been completed`));
      context.handleUserDestroy();
      history.push("/");
    } else return;
  };

  if (!context.isUser) return <Redirect to="/login" />;
  return (
    <>
      {/* <MainHeader /> */}
      <div className="container text-center mt-40 mb-40">
        <div className="sign-up-bottom text-left">
          <p className="margin-0 sign__up__text font-700 font-30">
            {/* 회원정보 */}
            {t(`User information`)}
          </p>

          <p className="margin-0 font-500 font-15 mb-12">
            {/* 이름 */}
            {t(`Name`)}
          </p>
          <input
            onChange={(e) => setName(e.target.value)}
            className="mb-12 font-18 pl-36 pr-36 id-input"
            // placeholder="이름을 입력하세요."
            placeholder={t(`Please enter your name`)}
            value={name}
          />

          <p className="margin-0 font-500 font-15 mb-12">
            {/* 아이디 (변경불가) */}
            {t(`ID cannot be changed`)}
          </p>
          <input
            // onChange={(e) => setId(e.target.value)}
            className="mb-12 font-18 pl-36 pr-36 id-input"
            // placeholder="아이디는 변경 불가능 합니다."
            placeholder={t(`The ID cannot be changed`)}
            value={id}
            disabled
          />

          <p className="margin-0 font-500 font-15 mb-12">
            {/* 연동 코드 (변경불가) */}
            {t(`Interworking code cannot be changed`)}
          </p>
          <input
            // onChange={(e) => setId(e.target.value)}
            className="mb-12 font-18 pl-36 pr-36 id-input"
            value={linkCode}
            disabled
          />

          <p className="margin-0 font-500 font-15 mb-12">
            {/* 이메일 (변경불가) */}
            {t(`Email cannot be changed`)}
          </p>
          {/* <form> */}
          <div className="position-r">
            <input
              type="email"
              // onChange={(e: any) => handleEmail(e)}
              className="mb-12 font-18 pl-36 pr-36 id-input email__input"
              // placeholder="이메일을 입력하세요."
              placeholder={t(`Please enter your email`)}
              value={email}
              disabled
            />
            {/* <span onClick={handleCheckEmail} className="position-a check-same-email cursor">
            중복확인
          </span> */}
          </div>
          {!verifyEmail() && (
            <p className="mt-m4 font-500 dark-red font-15 mb-12">
              {/* 이메일형식을 확인해주세요. */}
              {t(`Please check the email format`)}
            </p>
          )}

          {/* </form> */}
          <p className="margin-0 font-500 font-15 mb-12">
            {/* 전화번호 */}
            {t(`Phone number`)}
          </p>
          <input
            type="text"
            onChange={(e) => setPhone(e.target.value)}
            className="mb-12 font-18 pl-36 pr-36 pw-input"
            // placeholder="전화번호를 입력하세요."
            placeholder={t(`Please enter your Phone number`)}
            value={phone}
          />

          {/* <p className="margin-0 font-500 font-15 mb-12">기존 비밀번호</p>
        <input
          type="password"
          onChange={(e) => setCurrentPw(e.target.value)}
          className="mb-12 font-18 pl-36 pr-36 pw-input"
          placeholder="기존 비밀번호를 입력하세요."
          value={currentPw}
        /> */}
          <br />

          <p className="margin-0 font-500 font-15 mb-12">
            {/* 새로운 비밀번호 */}
            {t(`New password`)}
          </p>
          <input
            type="password"
            onChange={(e) => setPw(e.target.value)}
            className="mb-12 font-18 pl-36 pr-36 pw-input"
            // placeholder="비밀번호를 입력하세요."
            placeholder={t(`Please enter a password`)}
            value={pw}
          />
          <br />
          <p className="margin-0 font-500 font-15 mb-12">
            {/* 새로운 비밀번호 확인 */}
            {t(`Check your new password`)}
          </p>
          <input
            type="password"
            onChange={(e) => setPwCheck(e.target.value)}
            className="mb-33 font-18 pl-36 pr-36 pw-input"
            // placeholder="비밀번호를 다시 입력하세요."
            placeholder={t(`Please re-enter your password`)}
            value={pwCheck}
          />
          <br />

          <button onClick={handleSubmit} className="mb-26 font-16 font-500 sign-up-button mr-40">
            {/* 회원정보 수정 */}
            {t(`Modifying User Information`)}
          </button>
          <a href="/">
            <button className="mb-26 white font-16 font-500 cancel-button">
              {/* 취소 */}
              {t(`Cancel`)}
            </button>
          </a>

          <p
            onClick={handleCancelMembership}
            className="cursor"
            style={{ color: "#0067a3", textDecoration: "underline" }}
          >
            {/* 회원탈퇴 */}
            {t(`Membership withdrawal`)}
          </p>
        </div>
      </div>
    </>
  );
};

export default AuthEdit;
