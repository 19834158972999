import React, { useState, useEffect, useContext, useRef, useLayoutEffect } from "react";
import { MainContext } from "../common/Context";
import { IStudent, IMainContext } from "../interface/Interface";
import { useParams, Link } from "react-router-dom";
import search from "../images/search.png";
import down from "../images/down.png";
import {
  deletelist,
  getRecordTags,
  getStudents,
  postAddStudent,
  postNotificationAll,
  postStackRecord,
  postUpdate,
  putModifyStudentInfo,
} from "../common/Action";
import cookie1 from "../images/cookie1.png";
import cookie1S from "../images/cookie1S.png";
import cookie2S from "../images/cookie2S.png";
import cookie3S from "../images/cookie3S.png";
import cookie1G from "../images/cookie1G.png";
import cookie2G from "../images/cookie2G.png";
import cookie3G from "../images/cookie3G.png";
import excel_form from "../images/excel_form.png";

import * as xlsx from "xlsx";
import OutsideClickHandler from "react-outside-click-handler";
import { time3 } from "../common/Method";
import Modal from "../component/Modal";
import CSVSelector from "../component/CSVSelector";
import ModalComponent from "../component/ModalComponent";
import { t } from "i18next";

const Students: React.FC = () => {
  const context = useContext<IMainContext>(MainContext);
  const params: { classId: string } = useParams();
  const studentAddPopupRef = useRef<HTMLDivElement>(null);
  const popupGenderRef = useRef<HTMLDivElement>(null);
  const [cardData, setCardData] = useState<IStudent[]>([]);
  const [gender, setGender] = useState<string>("남자");
  const [genderDropped, setGenderDropped] = useState<boolean>(false);
  const [genderDropdown, setGetnderDropdown] = useState<string[]>(["남자", "여자"]);
  const [searchInput, setSearchInput] = useState<string>("");
  const [popupStudentNameInput, setPopupStudentNameInput] = useState<string>("");
  const [popupStudentNumberInput, setPopupStudentNumberInput] = useState<string>("");
  const [popupTransterInput, setPopupTransferInput] = useState<string>("");
  const [studentEdit, setStudentEdit] = useState<boolean>(false);
  const [allSelected, setAllSelected] = useState<boolean>(false);
  const [studentAddPopup, setStudentAddPopup] = useState<boolean>(false);
  const [checkboxChecked, setCheckboxChecked] = useState<boolean>(false);
  const [filter, setFilter] = useState<string[]>([
    t(`Number Order`),
    t(`Order of cookies received`),
  ]); // 번호 순, 받은쿠키 순
  const [filterSelected, setFilterSelected] = useState<string>(t(`Number Order`)); // 번호 순
  const [filterPopup, setFilterPopup] = useState<boolean>(false);
  const [stack, setStack] = useState<any>({
    cookie: false,
    report: false,
    useCookie: false,
    body: "",
    date: "",
  });
  const [cookieSelected, setCookieSelected] = useState<number>(1);
  const [recordTags, setRecordTags] = useState<any>([]);
  const [recordContents, setRecordContents] = useState<any>({
    studentsCode: [],
    tags: [],
    reportBody: "",
    reportDate: new Date(),
    increaseCookie: 0,
  });
  // date component에 들어갈 state
  const [dates, setDates] = useState<any[]>([]);
  const [editStudents, setEditStudents] = useState<boolean>(false);
  const [editStudentsData, setEditStudentsData] = useState<any>([]);
  const [easyStudentAddData, setEasyStudentAddData] = useState<any>([]);
  const [easyEditDeleteMode, setEasyEditDeleteMode] = useState<boolean>(false);
  const [easyEditDeleteAll, setEasyEditDeleteAll] = useState<boolean>(false);
  const [addStudentCellPopup, setAddStudentCellPopup] = useState<boolean>(false);
  const [editMode, setEditMode] = useState<boolean>(false);
  const [addCountPopup, setAddCountPopup] = useState<boolean>(false);
  const [sCount, setSCount] = useState<number>(0);
  const [phone, setPhone] = useState<any>("");
  const [jsonData, setJsonData] = useState<any>("");
  const csvRef = useRef<HTMLInputElement>(null);
  const [excelInfoModal, setExcelInfoModal] = useState<boolean>(false);

  useEffect(() => {
    if (context.selectClassCookie) {
      let tempStudents = [...cardData];
      for (let i = 0; i < tempStudents.length; i++) {
        tempStudents[i].checked = false;
      }
      setAllSelected(false);
      setCardData(tempStudents);
    }
  }, [context.selectClassCookie]);

  useEffect(() => {
    // context.handleStateChange("class", params.classId);
    init();
  }, []);

  useEffect(() => {
    if (studentAddPopup === false) {
      setPopupStudentNameInput("");
      setPopupStudentNumberInput("");
      setPopupTransferInput("");
    }
  }, [studentAddPopup]);

  useLayoutEffect(() => {
    context.handleStateChange("easyCookieEdit", false);
    context.handleStateChange("easyReport", false);
    context.handleStateChange("easyCookieUse", false);
  }, []);

  const init = async (): Promise<void> => {
    const result: any = await getStudents(params.classId);
    const recordTagsResult: any = await getRecordTags(params.classId);
    if (recordTagsResult.length !== 0) {
      setRecordTags(recordTagsResult[0]?.tags);
    }

    setCardData(result.sort((a: any, b: any) => parseInt(a.num) - parseInt(b.num)));
    setEditStudentsData(result.sort((a: any, b: any) => parseInt(a.num) - parseInt(b.num)));
  };

  const handleSelectAll = (): void => {
    const temp = [...cardData];
    for (let i = 0; i < temp.length; i++) {
      if (!allSelected) {
        temp[i].checked = true;
      } else {
        temp[i].checked = false;
      }
    }
    context.handleStateChange("selectClassCookie", false);
    setAllSelected((prev) => !prev);
    setCardData(temp);
  };

  const handleDeleteStudent = async (): Promise<void> => {
    const confirm = window.confirm(
      // "⛔ 정말 학생을 삭제하시겠습니까? \n 학생과 관련된 모든 데이터가 영구 삭제되며, 절대 복구 할 수 없습니다.",
      t(`Are you sure you want to delete the student...`),
    );
    let tempIdList: string[] = [];
    if (confirm) {
      for (let i in editStudentsData) {
        if (editStudentsData[i].checked) {
          tempIdList.push(editStudentsData[i]._id);
        }
      }

      const deleteResult = await deletelist("students", tempIdList);
      // alert("삭제되었습니다!");
      alert(t(`Deleted`));
    }
    setEditMode(false);
    setStudentEdit(false);
    setEasyEditDeleteMode(false);
    init();
  };

  const handleSearch = (e: any): void => {
    if (e.target.value === "") {
      const temp = [...cardData];
      for (let i in temp) {
        temp[i].searchHide = false;
      }
      setCardData(temp);
    } else {
      let temp: any = [...cardData];
      for (let i in temp) {
        if (
          temp[i].name.includes(e.target.value) ||
          JSON.stringify(temp[i].num).includes(e.target.value)
        ) {
          temp[i].searchHide = false;
        } else {
          temp[i].searchHide = true;
        }
      }
      setCardData(temp);
    }
    setSearchInput(e.target.value);
  };

  const handleCheckCard = (index: number): void => {
    const temp = [...cardData];
    for (let i = 0; i < temp.length; i++) {
      if (index === i) {
        temp[index].checked = !temp[index].checked;
      }
    }
    const checkedFilter = temp.filter((el: any) => el.checked);
    setCardData(temp);
    if (checkedFilter.length !== 0 && checkedFilter.length !== temp.length) {
      setAllSelected(false);
    }
    if (checkedFilter.length !== 0 && checkedFilter.length === temp.length) {
      setAllSelected(true);
    }

    if (context.selectClassCookie) {
      context.handleStateChange("selectClassCookie", false);
    }
  };

  const handleClickOutside = (e: any): void => {
    if (studentAddPopupRef.current !== null) {
      if (!studentAddPopupRef.current.contains(e.target)) setStudentAddPopup(false);
    }
    if (popupGenderRef.current !== null) {
      if (!popupGenderRef.current.contains(e.target)) setGenderDropped(false);
    }
  };

  const handleGenderSelect = (index: number): void => {
    let temp = "";
    for (let i = 0; i < genderDropdown.length; i++) {
      if (i === index) {
        temp = genderDropdown[index];
      }
    }
    setGender(temp);
    setGenderDropped(false);
  };

  const handleAddStudent = async (): Promise<void> => {
    let code = "";
    const possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

    for (let i = 0; i < 9; i++)
      code += possible.charAt(Math.floor(Math.random() * possible.length));

    const newStudentData = {
      name: popupStudentNameInput,
      num: parseInt(popupStudentNumberInput),
      // gender: gender,
      // transfer: popupTransterInput,
      code: code,
      classId: params.classId,
      checked: false,
      cookie: 0,
      phone,
    };
    await postAddStudent(newStudentData);
    setStudentAddPopup(false);
    init();
  };

  const handlePushNotificationAll = async (): Promise<void> => {
    let filteredStudentsData = cardData.filter(
      (data: any, i: number) => data.status !== "gray" && data.status !== "red",
    );

    if (filteredStudentsData.length === 0) {
      // return alert("알림을 보낼 학생이 없습니다.");
      return alert(t(`No students to send notifications to`));
    }
    const confirm = window.confirm(
      // `${filteredStudentsData.length}명의 학생에게 푸쉬 알림을 보냅니다.`,
      `${filteredStudentsData.length} ${t(`Send a push notification to the student`)}`,
    );
    if (confirm) {
      let pushMessages: any = [];
      filteredStudentsData.map((el: any, i: number) => {
        if (el.deviceToken2) {
          pushMessages.push({
            token: el.deviceToken2,
            notification: {
              title: t(`Notifications`), // "알림",
              body: t(`Check your homework`), // "숙제를 확인해 주세요.",
            },
            data: {
              type: "project",
              code: el.code,
            },
          });
        }
      });

      postNotificationAll(pushMessages);
      // alert("푸시알림을 보냈습니다.");
      alert(t(`Sent a push notification`));
    } else {
      return;
    }
  };

  const downloadExcel = () => {
    const arr = [];

    for (let i in cardData) {
      arr.push({
        num: cardData[i].num,
        name: cardData[i].name,
        code: cardData[i].code,
      });
    }

    const ws = xlsx.utils.json_to_sheet(arr);
    const wb = xlsx.utils.book_new();
    xlsx.utils.book_append_sheet(wb, ws, "Sheet1");

    ["번호", "이름", "코드"].forEach((x, idx) => {
      const cellAdd = xlsx.utils.encode_cell({ c: idx, r: 0 });
      ws[cellAdd].v = x;
    });

    xlsx.writeFile(wb, `학생코드(${context.class}).xlsx`);
  };

  const addStudentExcelForm = () => {
    const arr = [
      {
        num: "학생번호",
        name: "학생이름",
        code: "학생코드",
      },
    ];

    const ws = xlsx.utils.json_to_sheet(arr);
    const wb = xlsx.utils.book_new();
    xlsx.utils.book_append_sheet(wb, ws, "Sheet1");

    ["번호", "이름", "코드"].forEach((x, idx) => {
      const cellAdd = xlsx.utils.encode_cell({ c: idx, r: 0 });
      ws[cellAdd].v = x;
    });

    xlsx.writeFile(wb, `학생코드_업로드_양식.xlsx`);
  };

  const handleFilterSelect = async (index: number): Promise<void> => {
    let temp = "";
    temp = filter[index];
    setFilterSelected(temp);
    let filterData: any = [...cardData];

    if (filter[index] === t(`Number Order`)) {
      // 번호 순
      filterData = filterData.sort((a: any, b: any) => a.num - b.num);
    } else if (filter[index] === t(`Order of cookies received`)) {
      // 받은쿠키 순
      filterData = filterData.sort((a: any, b: any) => b.cookie - a.cookie);
    }

    setCardData(filterData);
    setFilterPopup(false);
  };

  const handleStackReport = (type: string) => {
    if (type === "cookie") {
      context.handleStateChange("easyCookieEdit", true);
      context.handleStateChange("easyReport", false);
      context.handleStateChange("easyCookieUse", false);
    }

    if (type === "report") {
      context.handleStateChange("easyReport", true);
      context.handleStateChange("easyCookieEdit", false);
      context.handleStateChange("easyCookieUse", false);
    }

    if (type === "useCookie") {
      context.handleStateChange("easyCookieUse", true);
      context.handleStateChange("easyReport", false);
      context.handleStateChange("easyCookieEdit", false);
    }

    setStack((prev: any) => {
      return {
        ...prev,
        [type]: true,
      };
    });
  };

  const handleSelectRecordTag = (tag: any): void => {
    const tempTags = [...recordTags];
    const findTagIdx = tempTags.findIndex((el: any) => el === tag);
    tempTags[findTagIdx].tagSelected = !recordTags[findTagIdx].tagSelected;
    setRecordTags(tempTags);
  };

  const handleEasyRecord = async (): Promise<any> => {
    let tempStudents = [];
    for (let i in cardData) {
      if (cardData[i].checked) {
        tempStudents.push(cardData[i].code);
      }
    }

    if (tempStudents.length === 0 && !context.selectClassCookie) {
      // return alert("대상을 선택해 주세요!");
      return alert(t(`Please select a target`));
    }

    let tempTags = [];
    for (let i = 0; i < recordTags?.length; i++) {
      if (recordTags[i]?.tagName && recordTags[i].tagSelected) {
        tempTags.push(i);
      }
    }

    // 쿠키쓰기
    if (stack.useCookie) {
      let temp = [];
      let tempCardData = [...cardData];

      for (let i = 0; i < tempCardData.length; i++) {
        let cookies = isNaN(Number(tempCardData[i].cookie)) ? 0 : Number(tempCardData[i].cookie);
        let usedCookies = isNaN(Number(tempCardData[i].usedCookie))
          ? 0
          : Number(tempCardData[i].usedCookie);

        if (tempCardData[i].checked && cookies - usedCookies < Number(cookieSelected)) {
          tempCardData[i].useCookieWarning = true;
          temp.push(tempCardData[i]);
        }
      }
      setCardData(tempCardData);

      if (temp.length > 0)
        return alert(
          // "남은 쿠키 수 보다 더 많은 쿠키를 뺄 수 없습니다.\n해당 학생은 체크 해제 해주세요.",
          t(`You can't subtract more cookies than you have left...`),
        );

      const postResult: any = await postStackRecord({
        studentsCode: tempStudents,
        body: {
          type: "useCookies",
          reportBody: recordContents.reportBody,
          tags: stack.cookie ? [] : tempTags,
          classId: params.classId,
          reportDate:
            recordContents.reportDate === ""
              ? new Date(recordContents.reportDate).getTime()
              : new Date(recordContents.reportDate).getTime(),
          increaseCookie: isNaN(Number(cookieSelected)) ? 0 : -Number(cookieSelected),
          studentsCode: tempStudents,
          useCookie: true,
        },

        // "쿠키쓰기"일 경우
        useCookie: true,
        increaseCookie: isNaN(Number(cookieSelected)) ? 0 : -Number(cookieSelected),
      });
    } else {
      const postResult: any = await postStackRecord({
        studentsCode: tempStudents,
        body: {
          reportBody: recordContents.reportBody,
          tags: stack.cookie ? [] : tempTags,
          classId: params.classId,
          reportDate:
            recordContents.reportDate === ""
              ? new Date(recordContents.reportDate).getTime()
              : new Date(recordContents.reportDate).getTime(),
          increaseCookie: stack.cookie ? Number(cookieSelected) : 0,
          studentsCode: tempStudents,
        },

        increaseCookie: stack.cookie ? Number(cookieSelected) : 0,
      });

      const dataResult = postResult;
      const studentsArr = Object.keys(dataResult.students);

      // dataresult badge 정보 받아서 푸시알림 보내기
      const tempPushArr: any = [];

      for (let i = 0; i < studentsArr.length; i++) {
        for (let k = 0; k < dataResult.students[studentsArr[i]].badgeList.length; k++) {
          tempPushArr.push({
            token: dataResult.students[studentsArr[i]].deviceToken2,
            notification: {
              title: `🎖${
                dataResult.badge.badges[dataResult.students[studentsArr[i]].badgeList[k]].title
              } ${t(`Badge`)}🎖`, // 뱃지
              body: t(`Congratulations Check it out`), // `축하합니다! 확인해보세요!`,
            },
            data: {
              type: "project",
              code: dataResult.students[studentsArr[i]].code,
            },
          });
        }
      }
      postNotificationAll(tempPushArr);
    }

    setStack((prev: any) => {
      return {
        cookie: false,
        report: false,
        useCookie: false,
        body: "",
        date: "",
      };
    });
    setCookieSelected(1);
    setRecordContents((prev: any) => {
      return { reportDate: new Date() };
    });

    init();
    setAllSelected(false);
    context.handleStateChange("easyCookieUse", false);
    context.handleStateChange("easyCookieEdit", false);
    context.handleStateChange("easyReport", false);
    context.handleStateChange("selectClassCookie", false);
    context.handleStateChange("class", "");
  };

  const handleSelectDate = (day: any) => {
    const tempDates: any = [];
    let exist: boolean = false;
    for (let i in dates) {
      if (dates[i] === day.valueOf()) {
        exist = true;
      } else {
        tempDates.push(dates[i]);
      }
    }
    if (!exist) tempDates.push(day.valueOf());
    if (tempDates.length === 22) {
      // return alert("과제는 21개 이상 등록하실 수 없습니다.");
      return alert(t(`Unable to register more than 21 assignments`));
    }

    setDates(tempDates);
  };

  const handleCancelRecord = () => {
    let temp = [...cardData];
    for (let i = 0; i < temp.length; i++) {
      temp[i].checked = false;
    }

    setCardData(temp);
    setStack((prev: any) => {
      return {
        ...prev,
        cookie: false,
        report: false,
        useCookie: false,
      };
    });

    setRecordContents({
      reportDate: new Date(),
    });
    setCookieSelected(1);
    context.handleStateChange("selectClassCookie", false);
    context.handleStateChange("easyReport", false);
    context.handleStateChange("easyCookieEdit", false);
    context.handleStateChange("easyCookieUse", false);
  };

  const handleEasyAddStudent = () => {
    setAddCountPopup(true);
  };

  const handleAddStudents = () => {
    let tempData: any = [...easyStudentAddData];
    for (let i = 0; i < sCount; i++) {
      tempData.push({
        num: "",
        name: "",
        phone: "",
        code: "",
      });
      setEditStudentsData((prev: any) => [
        ...prev,
        {
          num: "",
          name: "",
          phone: "",
          code: "",
        },
      ]);
    }

    setEasyStudentAddData(tempData);
    setAddCountPopup(false);
  };

  const handleChangeEasyAdd = (type: string, value: string, i: number): any => {
    let temp = [...easyStudentAddData];
    temp[i][type] = value;
    setEasyStudentAddData(temp);
  };

  const handleChangeEasyEditStudent = (type: string, value: string, i: number): any => {
    let temp: any = JSON.parse(JSON.stringify(editStudentsData));
    temp[i][type] = value;
    setEditStudentsData(temp);
  };

  const handleEditStudent = (student: any) => {
    if (editMode) {
      let temp: any = JSON.parse(JSON.stringify(editStudentsData));
      const findIdx = editStudentsData.findIndex((el: any) => el === student);

      temp[findIdx].editState = true;
      setEditStudentsData(temp);
    }
  };

  const onHandleStudents = async (): Promise<void> => {
    // 1. editStudentsData 돌아서 editState true인것은 업데이트
    // 2. easyStudentAddData 돌면서 insertMany 한다..!

    // 기존 학생 수정
    const filterData: any = editStudentsData.filter((el: any) => el.editState);
    if (filterData.length != 0) {
      let updateArr = [];
      for (let i in filterData) {
        updateArr.push({
          code: filterData[i].code,
          name: filterData[i].name,
          num: filterData[i].num,
          phone: filterData[i].phone,
        });
      }

      if (updateArr.length !== 0) {
        for (let i in updateArr) {
          await putModifyStudentInfo(updateArr[i]);
        }
      }
    }

    // 새로 등록
    if (easyStudentAddData.length !== 0) {
      let insertArr = [];
      for (let i in easyStudentAddData) {
        let code = "";
        const possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
        for (let k = 0; k < 9; k++)
          code += possible.charAt(Math.floor(Math.random() * possible.length));
        if (easyStudentAddData[i].name !== "" || easyStudentAddData[i].num) {
          insertArr.push({
            name: easyStudentAddData[i].name,
            num: easyStudentAddData[i].num,
            code: code,
            classId: params.classId,
            checked: false,
            cookie: 0,
            phone: easyStudentAddData[i].phone,
          });
        }
      }

      for (let i in insertArr) {
        await postAddStudent(insertArr[i]);
      }
    }

    setEditMode(false);
    setEasyStudentAddData([]);
    init();
  };

  const handleCancelEditStudents = () => {
    if (!easyEditDeleteMode && !editMode) {
      setEditStudents(false);
    }

    setSCount(0);
    setAddCountPopup(false);
    setEasyEditDeleteMode(false);
    setEditStudentsData(cardData);
    setEasyStudentAddData([]);
    setEditMode(false);
  };

  const handleSelectDelete = (student: any, i: number) => {
    const temp = JSON.parse(JSON.stringify(editStudentsData));
    temp[i].checked = !editStudentsData[i].checked;

    setCardData(temp);
    setEditStudentsData(temp);
  };

  const handleCheckAllStudents = () => {
    // 학생관리 전체학생선택
    let temp = JSON.parse(JSON.stringify(editStudentsData));
    if (easyEditDeleteAll) {
      for (let i in temp) {
        temp[i].checked = false;
      }
    } else {
      for (let i in temp) {
        temp[i].checked = true;
      }
    }
    setEditStudentsData(temp);
    setEasyEditDeleteAll((prev: boolean) => !prev);
  };

  const removeFileName = (originalFileName: string): string => {
    const lastIndex = originalFileName.lastIndexOf(".");

    if (lastIndex < 0) {
      return "";
    }

    return originalFileName.substring(lastIndex + 1).toLowerCase();
  };

  return (
    <div className="col-md-12 category_container">
      {studentEdit ? (
        <div className="display-f justify-between align-center mb-20 pl-10 pr-10">
          <div className="display-f align-center">
            <button onClick={handleSelectAll} className="select_all_btn mr-10 position-r">
              {allSelected && (
                <span className={`check__inside select_all position-a bg-checked`}></span>
              )}
            </button>
            <p className="font-16 mt-9 mb-10">{t(`Select All`)}</p>
          </div>
          <button onClick={handleDeleteStudent} className="select_option_btn">
            {/* 삭제 */}
            {t(`Delete`)}
          </button>

          <div>
            {/* <button onClick={() => setStudentEdit(false)} className="select_submit_btn mr-13">
              확인
            </button> */}
            <button onClick={() => setStudentEdit(false)} className="select_submit_btn">
              {/* 취소 */}
              {t(`Cancel`)}
            </button>
          </div>
        </div>
      ) : (
        <div
          className={`display-f ${
            !stack.cookie && !stack.stack ? "justify-between" : ""
          } align-center mb-20 pl-10 pr-10`}
          style={{ flexWrap: "wrap", width: "100%" }}
        >
          <div className="display-f mr-10">
            {/* {!context.easyCookieEdit && !context.easyReport && (
              <Link to={`/page/report/${params.classId}/tab1`}>
                <button className="mr-28 btn_blue">우리반 리포트</button>
              </Link>
            )} */}

            {!context.easyCookieEdit && !context.easyReport && !context.easyCookieUse && (
              <div className="display-f">
                <button
                  onClick={() => handleStackReport("report")}
                  className="mr-10 btn-border-blue bg-none"
                >
                  {/* 누가기록 */}
                  <p>{t(`Anecdotal Record`)}</p>
                </button>

                <div className="display-f direction-column">
                  <button
                    onClick={() => handleStackReport("cookie")}
                    style={{
                      height: 30,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                    className={`btn_brown ${stack.cookie && "yellow"}`}
                  >
                    {/* <img src={cookie1} style={{ width: 20, height: "auto" }} /> */}
                    <p>
                      {/* 쿠키 지급 */}
                      🍪 {t(`Reward Cookie`)}
                    </p>
                  </button>
                  <button
                    style={{
                      height: 30,
                    }}
                    onClick={() => handleStackReport("useCookie")}
                    className="btn_red mt-5"
                  >
                    <p>
                      {/* 쿠키 쓰기 */}
                      🍪 {t(`Use cookies`)}
                    </p>
                  </button>
                </div>
              </div>
            )}
          </div>

          {(stack.cookie || stack.report || stack.useCookie) && (
            <div className="display-f easy_cookie_container">
              <div className="display-f" style={{ width: "100%", flexDirection: "column" }}>
                <div className="display-f">
                  <button
                    onClick={() => {
                      context.handleStateChange("easyCookieEdit", false);
                      context.handleStateChange("easyReport", true);
                      context.handleStateChange("easyCookieUse", false);
                      setStack((prev: any) => {
                        return {
                          ...prev,
                          useCookie: false,
                          cookie: false,
                          report: true,
                        };
                      });
                    }}
                    className={`mr-10 ${stack.report ? "btn-bg-blue" : "btn-border-blue"}`}
                  >
                    <p>
                      {/* 누가기록 */}
                      {t(`Anecdotal Record`)}
                    </p>
                  </button>
                  <div
                    className="display-f align-center justify-between"
                    style={{ flex: 1, width: "100%" }}
                  >
                    <div className="display-f" style={{ alignItems: "flex-end", flex: 1 }}>
                      <div className="display-f direction-column mr-10">
                        <button
                          onClick={() => {
                            context.handleStateChange("easyCookieEdit", true);
                            context.handleStateChange("easyReport", false);
                            context.handleStateChange("easyCookieUse", false);

                            setStack((prev: any) => {
                              return {
                                ...prev,
                                cookie: true,
                                report: false,
                                useCookie: false,
                              };
                            });
                          }}
                          style={{
                            // width: 100, // added 240619 주석처리
                            height: 30,
                          }}
                          className={`btn_brown ${stack.cookie && "yellow"}`}
                        >
                          {/* 쿠키 지급 */}
                          🍪 {t(`Reward Cookie`)}
                        </button>
                        <button
                          style={{
                            // width: 100, // added 240619 주석처리
                            height: 30,
                          }}
                          onClick={() => {
                            context.handleStateChange("easyCookieEdit", false);
                            context.handleStateChange("easyCookieUse", true);
                            context.handleStateChange("easyReport", false);
                            setStack((prev: any) => {
                              return {
                                ...prev,
                                cookie: false,
                                report: false,
                                useCookie: true,
                              };
                            });
                          }}
                          className={`btn_red mt-5 ${stack.useCookie && "red"}`}
                        >
                          {/* 쿠키 쓰기 */}
                          🍪 {t(`Use cookies`)}
                        </button>
                      </div>
                      <div className="display-f align-center position-r">
                        <div onClick={handleSelectAll} className="check cursor mr-5">
                          {allSelected && (
                            <div
                              style={{ left: 5, top: 5 }}
                              className={`check__inside position-a bg-checked`}
                            />
                          )}
                        </div>
                        <p onClick={handleSelectAll} className="white cursor mr-20">
                          {/* 전체 학생 선택 */}
                          {t(`Select All Student`)}
                        </p>
                      </div>
                    </div>

                    <div style={{ flex: 3 }}>
                      <div className="display-f align-center justify-center">
                        {stack.cookie && (
                          <>
                            <button
                              onClick={() => setCookieSelected(1)}
                              className={`${
                                cookieSelected !== 1 && "cookie_border_none"
                              } cookie_circle cursor display-f-center mr-17`}
                            >
                              {cookieSelected === 1 ? (
                                <img className="cookie1" src={cookie1S} alt="cookie1S" />
                              ) : (
                                <img className="cookie1" src={cookie1G} alt="cookie1G" />
                              )}
                            </button>
                            <button
                              onClick={() => setCookieSelected(2)}
                              className={`${
                                cookieSelected !== 2 && "cookie_border_none"
                              } cookie_circle cursor display-f-center mr-17`}
                            >
                              {cookieSelected === 2 ? (
                                <img className="cookie2" src={cookie2S} alt="cookie2S" />
                              ) : (
                                <img className="cookie2" src={cookie2G} alt="cookie2G" />
                              )}
                            </button>
                            <button
                              onClick={() => setCookieSelected(3)}
                              className={`${
                                cookieSelected !== 3 && "cookie_border_none"
                              } cookie_circle cursor display-f-center mr-17`}
                            >
                              {cookieSelected === 3 ? (
                                <img className="cookie3" src={cookie3S} alt="cookie3S" />
                              ) : (
                                <img className="cookie3" src={cookie3G} alt="cookie3G" />
                              )}
                            </button>

                            <div className="mr-20 display-f align-center">
                              <input
                                value={cookieSelected}
                                onChange={(e: any) => {
                                  const numParse = parseInt(e.nativeEvent.data);
                                  if (e.nativeEvent.data === null && e.target.value === "") {
                                    setCookieSelected(0);
                                  }
                                  if (e.nativeEvent.data === null && e.target.value !== "") {
                                    setCookieSelected(parseInt(e.target.value));
                                  }
                                  if (!isNaN(numParse)) {
                                    setCookieSelected(parseInt(e.target.value));
                                  }
                                }}
                                className="add_cookie_input"
                                style={{ fontSize: 20 }}
                              />
                              <span className="white">개</span>
                            </div>
                          </>
                        )}

                        {stack.useCookie && (
                          <div>
                            <div className="mr-20 display-f align-center">
                              <input
                                value={cookieSelected}
                                onChange={(e: any) => {
                                  const numParse = parseInt(e.nativeEvent.data);
                                  if (e.nativeEvent.data === null && e.target.value === "") {
                                    setCookieSelected(0);
                                  }
                                  if (e.nativeEvent.data === null && e.target.value !== "") {
                                    setCookieSelected(parseInt(e.target.value));
                                  }
                                  if (!isNaN(numParse)) {
                                    setCookieSelected(parseInt(e.target.value));
                                  }
                                }}
                                className="add_cookie_input text-red"
                                style={{ fontSize: 20 }}
                              />
                              <span className="white">개</span>
                            </div>
                          </div>
                        )}

                        {!stack.cookie && !stack.useCookie && stack.report && (
                          <div className="display-f mr-20">
                            {recordTags.map((tag: any, i: number) => (
                              <>
                                {tag.tagName && tag.tagName !== "" && (
                                  <button
                                    onClick={() => handleSelectRecordTag(tag)}
                                    className={`${
                                      tag.tagSelected ? "blue font-500" : ""
                                    } stack_tag_btn cursor`}
                                    style={{
                                      backgroundColor: "rgba(38,97,217,0.4)",
                                      padding: "3px 0",
                                    }}
                                  >
                                    <p className={`white`}>{tag.tagName}</p>
                                  </button>
                                )}
                              </>
                            ))}
                          </div>
                        )}

                        <div className="display-f align-center">
                          <p style={{ fontSize: 16 }} className="white font-500 mr-10">
                            {/* 날짜 */}
                            {t(`Date`)}
                          </p>

                          <input
                            className="students_stack_date_input"
                            onChange={(e: any) =>
                              setRecordContents((prev: any) => {
                                return {
                                  ...prev,
                                  reportDate: e.target.value,
                                };
                              })
                            }
                            value={`${time3(recordContents.reportDate)}`}
                            type="date"
                          />
                        </div>
                      </div>
                      {stack.useCookie && (
                        <p className="white cursor font-12 text-center mt-4">
                          {/* 쿠키 쓰기 되돌리기 (- 값 입력)은 ‘개별 학생 쿠키쓰기‘ 에서 할 수 있습니다. */}
                          {t(`You can reverse cookie writing...`)}
                          <br />
                          {/* 우리반 리포트의 ‘누가기록‘ 에서 쿠키 쓰기 기록을 확인하거나 삭제할 수
                          있습니다. */}
                          {t(`You can view or delete cookie writing history...`)}
                        </p>
                      )}
                    </div>

                    <div style={{ flex: 1, textAlign: "end" }}>
                      <button
                        onClick={handleEasyRecord}
                        style={{ width: 80, height: 30, backgroundColor: "#fff" }}
                        className="display-ib mr-10 select_btn text-red border-red font-500"
                      >
                        {/* 저장 */}
                        {t(`Save`)}
                      </button>
                      <button
                        className="select_btn display-ib"
                        style={{ width: 80, height: 30 }}
                        onClick={handleCancelRecord}
                      >
                        {/* 취소 */}
                        {t(`Cancel`)}
                      </button>
                    </div>
                  </div>
                </div>

                <div className="mt-10">
                  <input
                    value={recordContents.reportBody}
                    onChange={(e: any) =>
                      setRecordContents((prev: any) => {
                        return {
                          ...prev,
                          reportBody: e.target.value,
                        };
                      })
                    }
                    className="easy_check_cookie text-center"
                    placeholder={t(`Please briefly note`)} // 내용을 간단히 입력해 주세요
                  />
                </div>
              </div>
            </div>
          )}

          {!stack.cookie && !stack.report && !stack.useCookie && (
            <div>
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <button
                  onClick={handlePushNotificationAll}
                  style={{ margin: 0 }}
                  className="push_all_btn font-500 mr-5"
                >
                  {/* 전체 Push 보내기 */}
                  {t(`Send All Push Alarms`)}
                </button>
                {/* <button
                  style={{ margin: 0 }}
                  className="student_code_upload_btn mr-5"
                  onClick={downloadExcel}
                >
                  학생코드 일괄 내려받기
                </button> */}
                <button onClick={() => setEditStudents(true)} className="select_btn mr-5">
                  {/* 학생 관리 */}
                  {t(`Student Enrollment`)}
                </button>
                {/* <button onClick={() => setStudentEdit(true)} className="select_btn">
                  편집
                </button> */}
              </div>
              <div className="mt-10">
                <div className="display-f position-r">
                  <form className="mr-28 position-r" action={`/projects/${searchInput}`}>
                    <img className="search_icon position-a cursor" src={search} alt="search" />
                    <input
                      value={searchInput}
                      onChange={handleSearch}
                      type="text"
                      className="category_searchInput pl-50 pr-10"
                    />
                  </form>

                  <OutsideClickHandler
                    onOutsideClick={() => {
                      setFilterPopup(false);
                    }}
                  >
                    <button
                      onClick={() => setFilterPopup((prev) => !prev)}
                      className="filter_btn text-left pl-17 pr-12 display-f justify-between align-center"
                    >
                      {filterSelected} <img className="filter_icon" src={down} alt="down" />
                    </button>
                    {filterPopup && (
                      <div className="position-a filter_contents">
                        {filter.map((data: string, index: number) => (
                          <div
                            onClick={() => handleFilterSelect(index)}
                            className="filter_inner cursor"
                            key={index}
                          >
                            <p className="pl-17 pt-11 pb-11 font-14">{data}</p>
                          </div>
                        ))}
                      </div>
                    )}
                  </OutsideClickHandler>
                </div>
              </div>
            </div>
          )}
        </div>
      )}

      <div className="display-f flex-wrap">
        {cardData?.map((data: IStudent, index: number) => {
          if (
            studentEdit ||
            context.easyCookieEdit ||
            context.easyReport ||
            context.easyCookieUse
          ) {
            return (
              <div key={index} className="display-ib pl-10 pr-10 position-r">
                <div
                  style={{ border: data.useCookieWarning ? "3px solid red" : "" }}
                  onClick={() => handleCheckCard(index)}
                  className={`card student_inner_card justify-center padding-0 background-${data.status}`}
                >
                  <div className="display-f mb-9 direction-column mt-10">
                    <p className="font-15 color-dark font-700 mb-3">{data.name}</p>
                    <div className="display-f align-center">
                      <img src={cookie1} style={{ width: 20, height: 20, marginLeft: -4 }} />
                      <p className="font-13">
                        {Number(data?.cookie) - Number(data?.usedCookie ? data.usedCookie : 0)} /{" "}
                        <span className="font-13 bold-900">{data.cookie ? data.cookie : 0}</span>
                      </p>
                    </div>
                  </div>
                  <span className="position-a check cursor">
                    {data.checked && (
                      <span className={`check__inside position-a bg-checked`}></span>
                    )}
                  </span>
                  <p className={`position-a student_num font-29 font-700 card-text-${data.status}`}>
                    {data.num}
                  </p>
                </div>
              </div>
            );
          }

          if (!studentEdit && !data.searchHide) {
            return (
              <Link key={index} to={`/page/student/${params.classId}/${data._id}`}>
                <div key={index} className="display-ib pl-10 pr-10 position-r">
                  <div
                    className={`card student_inner_card justify-center padding-0 ${data.status}`}
                  >
                    <div className="display-f mb-9 direction-column mt-10">
                      <p className="font-15 color-dark font-700 mb-3">{data.name}</p>

                      <div className="display-f align-center">
                        <img src={cookie1} style={{ width: 20, height: 20, marginLeft: -4 }} />
                        <p className="font-13 font-500">
                          {" "}
                          {Number(data?.cookie) -
                            Number(data?.usedCookie ? data.usedCookie : 0)} /{" "}
                          <span style={{ fontWeight: 900, fontSize: 14 }}>
                            {data.cookie ? data.cookie : 0}
                          </span>
                        </p>
                      </div>
                    </div>
                    <p
                      className={`position-a student_num font-29 font-700 card-text-${data.status}`}
                    >
                      {data.num}
                    </p>
                  </div>
                </div>
              </Link>
            );
          }
        })}

        {!studentEdit &&
          !context.easyCookieEdit &&
          !context.easyReport &&
          !context.easyCookieUse && (
            <div className="display-ib pl-10 pr-10">
              <div
                onClick={() => setStudentAddPopup(true)}
                className="card student_inner_card padding-0 justify-center project_card"
              >
                <p className="font-34 font-900">+</p>
              </div>
            </div>
          )}
      </div>

      {studentAddPopup && (
        <div className="popup">
          <div className="modal-overlay" onClick={(e: any) => handleClickOutside(e)} />
          <div
            // onClick={(e: any) => handleClickOutside(e)}
            ref={studentAddPopupRef}
            className="main_popup scroll"
          >
            <p className="text-center font-23 mb-26">{t(`Enroll New Student`)}</p>
            <div className="pl-36 pr-36 font-15">
              <div className="mb-30">
                <p className="color-light-black mb-8">{t(`Student Name`)} (*)</p>
                <input
                  value={popupStudentNameInput}
                  onChange={(e: any) => setPopupStudentNameInput(e.target.value)}
                  className="mb-25 popup_input_default"
                  type="text"
                />

                <div className="display-f mb-25">
                  <div className="mr-23">
                    <p className="color-light-black mb-8">{t(`Student Number`)}</p>
                    <input
                      value={popupStudentNumberInput}
                      onChange={(e: any) => setPopupStudentNumberInput(e.target.value)}
                      className="popup_input_default popup_student"
                      type="text"
                    />
                  </div>

                  {/* <div>
                    <p className="color-light-black mb-8">성별</p>
                    <div className="position-r display-f">
                      <input
                        className="popup_input_default popup_student cursor-default"
                        value={gender}
                        readOnly
                        type="text"
                      />
                      <img
                        onClick={() => setGenderDropped((prev) => !prev)}
                        className="popup_down position-a cursor"
                        src={down}
                        alt="down"
                      />
                      {genderDropped && (
                        <div ref={popupGenderRef} className="position-a popup_gender_dropdown">
                          {genderDropdown.map((data: string, index: number) => (
                            <p
                              onClick={() => handleGenderSelect(index)}
                              className="popup_gender_dropdown_contents text-center cursor"
                              key={index}
                            >
                              {data}
                            </p>
                          ))}
                        </div>
                      )}
                    </div>
                  </div> */}
                </div>
                <p className="color-light-black mb-8">{t(`Contact`)}</p>
                <div className="position-r display-f">
                  <input
                    placeholder={t(`Optional When Filling in Excluding...`)}
                    style={{ width: "100%" }}
                    className="popup_input_default popup_student"
                    value={phone}
                    onChange={(e: any) => setPhone(e.target.value)}
                    // readOnly
                    type="number"
                  />
                </div>
                {/* <div className="display-f align-center mb-8">
                  <p className="color-light-black mr-5">전학일</p>
                  <div
                    onClick={() => setCheckboxChecked((prev) => !prev)}
                    className="popup_checkbox position-r cursor"
                  >
                    {checkboxChecked && <div className="square_check position-a"></div>}
                  </div>
                </div>
                <input
                  value={popupTransterInput}
                  onChange={(e: any) => setPopupTransferInput(e.target.value)}
                  className="popup_input_default mb-8"
                  disabled={!checkboxChecked}
                  type="date"
                /> */}
                {/* <p className="font-12">* 전학생일 때만 설정해 주세요.</p> */}
              </div>
              <div className="popup__bottom text-center mb-20">
                <button onClick={handleAddStudent} className="focus popup__bottom__btn mr-14">
                  {/* 확인 */}
                  {t(`Confirm`)}
                </button>
                <button
                  onClick={() => setStudentAddPopup(false)}
                  className="focus popup__bottom__btn"
                >
                  {/* 취소 */}
                  {t(`Cancel`)}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {addCountPopup && (
        <Modal show={addCountPopup} onClose={handleCancelEditStudents}>
          <div
            className="stack_report_container add_student_popup_container"
            style={{ width: "20vw", height: "20vh" }}
          >
            <div className="add_student_wrapper text-center">
              <h2>
                {/* 학생 추가 */}
                {t(`Enroll New Student`)}
              </h2>

              <div className="display-f align-center">
                <input
                  onChange={(e: any) => {
                    if (e.target.value < 0) return;
                    else {
                      setSCount(e.target.value);
                    }
                  }}
                  value={sCount}
                  type="number"
                  className="student_add_input mr-10"
                  placeholder={t(`Number of students to add`)} //"추가할 학생 수"
                />
                <div>
                  <h4>
                    {/* 명 */}
                    {localStorage.getItem("language") === "ko" ? "명" : ""}
                  </h4>
                </div>
              </div>

              <div className="display-f mt-15">
                <button
                  onClick={handleAddStudents}
                  className="edit_student_btn border-none mr-10 yellow white"
                >
                  {/* 추가 */}
                  {t(`Add`)}
                </button>
              </div>
            </div>
          </div>
        </Modal>
      )}

      {excelInfoModal && (
        <ModalComponent
          modalStyles={{
            width: "40%",
            minHeight: "40vh",
            borderRadius: 16,
            backgroundColor: "#efefef",
          }}
          onClose={() => setExcelInfoModal(false)}
          // title={"모달타이틀"}
          open={excelInfoModal}
        >
          <div style={{ padding: 25, textAlign: "center" }}>
            <h1>
              {/* 엑셀파일 업로드 양식 안내 */}
              {t(`Excel file upload form instructions`)}
            </h1>

            <p className="font-18">
              {/* 엑셀의 세 행의 각 헤더에  */}
              {t(`In the header of each of the three rows...`)}
              <span className="color-red">
                {/* 번호, 이름, 연락처 */}
                {t(`Number Name Contact`)}
              </span>
              {/* 를 쓰고 */}
              {t(`And write`)}
              <br />
              {/* 아래에 각 학생의 번호, 이름, 연락처 기입, 저장 후 파일을 업로드하세요. */}
              {t(`Fill in each student's number name and contact...`)}
            </p>

            <img className="mt-20" src={excel_form} style={{ width: "70%", height: "auto" }} />
          </div>
        </ModalComponent>
      )}

      {editStudents && (
        <Modal show={editStudents} onClose={handleCancelEditStudents}>
          <div
            className="stack_report_container add_student_popup_container"
            style={{ width: "40vw", height: "70vh", zIndex: 100, backgroundColor: "#fff" }}
          >
            <div className="add_students_modal_head">
              <div className="display-f justify-between align-center">
                <div className="display-f justify-between align-center" style={{ width: "100%" }}>
                  <div className="display-f align-center">
                    <h2 className="mr-10">{t(`Student Enrollment`)}</h2>
                    {editMode && (
                      <span className="card-text-red" style={{ fontSize: 12 }}>
                        {/* 학생을 클릭하여 수정해주세요. */}
                        {t(`Click the student to edit it`)}
                      </span>
                    )}
                  </div>
                </div>

                <div
                  className="display-f align-center"
                  style={{ width: "100%", justifyContent: "flex-end" }}
                >
                  {editMode && (
                    <div className="display-f align-center" style={{ textAlign: "right" }}>
                      <CSVSelector
                        ref={csvRef}
                        onChange={(_data: any) => {
                          console.log(_data);
                          setJsonData(_data);

                          let temp: any = [];
                          for (let i = 0; i < _data.length; i++) {
                            if (_data[i]["이름"]) {
                              temp.push({
                                name: _data[i]["이름"],
                                num: _data[i]["번호"],
                                phone: _data[i]["연락처"],
                              });
                            } else {
                              return alert(
                                // "양식을 지켜 업로드해 주세요. 학생이름 항목은 필수입니다.",
                                t(`Please follow the form to upload...`),
                              );
                            }
                          }

                          setEasyStudentAddData((prev: any) => [...prev, ...temp]);
                          setEditStudentsData((prev: any) => [...prev, ...temp]);
                        }}
                      />

                      <button onClick={() => csvRef?.current?.click()}>일괄업로드</button>

                      {/* <CSVLink
                        data={[]}
                        headers={[
                          { label: "번호", key: "num" },
                          { label: "이름", key: "name" },
                          { label: "연락처", key: "phone" },
                        ]}
                        // confirm 창에서 '확인'을 눌렀을 때만 csv 파일 다운로드
                        onClick={() => {
                          if (window.confirm("csv파일을 다운로드 받겠습니까?")) {
                            return true;
                          } else {
                            return false;
                          }
                        }}
                        filename={`학생코드_등록_양식.csv`}
                      > */}
                      <button
                        onClick={() => setExcelInfoModal(true)}
                        className="cursor color-blue"
                        style={{
                          padding: "2px 10px",
                          borderBottom: "1px solid #333",
                          backgroundColor: "#fff",
                          border: "none",
                          marginLeft: 4,
                          borderRadius: 2,
                          textDecoration: "underline",
                        }}
                      >
                        {/* ℹ 엑셀양식 안내 */}i {t(`Guide to Excel forms`)}
                      </button>
                      {/* </CSVLink> */}
                    </div>
                  )}

                  {!editMode && !easyEditDeleteMode && (
                    <div className="display-f" style={{ flexShrink: 0 }}>
                      <button
                        style={{ border: "1px solid #232323", borderRadius: 4 }}
                        className="mr-5"
                        onClick={downloadExcel}
                      >
                        {/* 학생코드 다운로드 */}
                        {t(`Download Student Code`)}
                      </button>

                      <button
                        onClick={() => setEasyEditDeleteMode(true)}
                        style={{ margin: 0, height: 30 }}
                        className="push_all_btn mr-5"
                      >
                        {/* 학생삭제 */}
                        {t(`Delete Student`)}
                      </button>
                    </div>
                  )}

                  {easyEditDeleteMode && (
                    <button
                      onClick={handleDeleteStudent}
                      className="students_delete push_all_btn mr-5"
                    >
                      {/* 영구 삭제 */}
                      {t(`Permenantly Delete`)}
                    </button>
                  )}
                </div>
              </div>
              <table className="students_edit_table" style={{ borderBottom: 0 }}>
                <tr style={{ backgroundColor: "#ececec" }}>
                  {easyEditDeleteMode && (
                    <th style={{ width: "5%", borderBottom: 0 }}>
                      <div className="display-f justify-center">
                        <div
                          onClick={handleCheckAllStudents}
                          className="select_stuent_c"
                          style={{ margin: 0 }}
                        >
                          {easyEditDeleteAll && <div className="select_stuent_c_fill" />}
                        </div>
                      </div>
                    </th>
                  )}
                  <th style={{ width: "10%", borderBottom: 0 }}>
                    {/* 번호 */}
                    {t(`Number`)}
                  </th>
                  <th style={{ width: "30%", borderBottom: 0 }}>
                    {/* 이름 */}
                    {t(`Name`)}
                  </th>
                  <th style={{ width: "30%", borderBottom: 0 }}>
                    {/* 연락처 */}
                    {t(`Contact`)}
                  </th>
                  <th style={{ width: "20%", borderBottom: 0 }}>
                    {/* 학생코드 */}
                    {t(`Student Code`)}
                  </th>
                </tr>
              </table>
            </div>
            <table className="stack_report_table" style={{ overflow: "auto", borderBottom: 0 }}>
              {editStudentsData?.map((student: any, i: number) => (
                <>
                  {student.code && (
                    <tr className="text-center cursor" style={{ height: 30 }}>
                      {easyEditDeleteMode && (
                        <td style={{ width: "5%" }} onClick={() => handleSelectDelete(student, i)}>
                          <div
                            className="display-f align-center justify-center position-r"
                            style={{ zIndex: 1 }}
                          >
                            <div className="select_stuent_c" style={{ margin: 0 }}>
                              {student.checked && <div className="select_stuent_c_fill" />}
                            </div>
                          </div>
                        </td>
                      )}
                      {!student.editState && (
                        <td onClick={() => handleEditStudent(student)} style={{ width: "10%" }}>
                          {student.num}
                        </td>
                      )}
                      {student.editState && (
                        <td style={{ width: "10%" }}>
                          <input
                            style={{ width: "90%", textAlign: "center" }}
                            value={editStudentsData[i].num}
                            onChange={(e: any) =>
                              handleChangeEasyEditStudent("num", e.target.value, i)
                            }
                          />
                        </td>
                      )}
                      {!student.editState && (
                        <td onClick={() => handleEditStudent(student)} style={{ width: "30%" }}>
                          {student.name}
                        </td>
                      )}
                      {!student.editState && (
                        <td onClick={() => handleEditStudent(student)} style={{ width: "30%" }}>
                          {student.phone}
                        </td>
                      )}

                      {student.editState && (
                        <td style={{ width: "30%" }}>
                          <input
                            value={editStudentsData[i].name}
                            onChange={(e: any) =>
                              handleChangeEasyEditStudent("name", e.target.value, i)
                            }
                            style={{ width: "90%", textAlign: "center" }}
                          />
                        </td>
                      )}

                      {student.editState && (
                        <td style={{ width: "30%" }}>
                          <input
                            value={editStudentsData[i].phone}
                            onChange={(e: any) =>
                              handleChangeEasyEditStudent("phone", e.target.value, i)
                            }
                            style={{ width: "90%", textAlign: "center" }}
                          />
                        </td>
                      )}

                      <td style={{ width: "20%" }}>{student.code}</td>
                    </tr>
                  )}
                </>
              ))}

              {/* 새로 추가될 학생 리스트 */}
              {easyStudentAddData.map((el: any, index: number) => (
                <tr key={index} className="text-center" style={{ height: 30 }}>
                  {easyEditDeleteMode && <td style={{ width: "5%" }}></td>}
                  <td style={{ width: "10%" }}>
                    <input
                      style={{ width: "90%", textAlign: "center" }}
                      value={easyStudentAddData[index].num}
                      onChange={(e: any) => handleChangeEasyAdd("num", e.target.value, index)}
                    />
                  </td>
                  <td style={{ width: "30%" }}>
                    <input
                      value={easyStudentAddData[index].name}
                      onChange={(e: any) => handleChangeEasyAdd("name", e.target.value, index)}
                      style={{ width: "90%", textAlign: "center" }}
                    />
                  </td>
                  <td style={{ width: "30%" }}>
                    <input
                      value={easyStudentAddData[index].phone}
                      onChange={(e: any) => handleChangeEasyAdd("phone", e.target.value, index)}
                      style={{ width: "90%", textAlign: "center" }}
                    />
                  </td>

                  <td style={{ width: "20%", backgroundColor: "#ececec" }}>
                    {/* <button>학생생성하기</button> */}
                  </td>
                </tr>
              ))}
            </table>
            {editMode && !easyEditDeleteMode && (
              <div
                onClick={handleEasyAddStudent}
                className="students_add_wrapper mt-5 display-f align-center justify-center cursor"
              >
                +
              </div>
            )}

            <div className="display-f justify-center mt-20">
              <button
                onClick={handleCancelEditStudents}
                className="edit_student_btn border-none mr-10"
                style={{ color: "#979797" }}
              >
                {/* 취소 */}
                {t(`Cancel`)}
              </button>

              {!editMode && !easyEditDeleteMode && (
                <button
                  onClick={() => {
                    setEditMode(true);
                  }}
                  className="edit_student_btn border-none yellow white"
                >
                  {/* 수정 */}
                  {t(`Edit`)}
                </button>
              )}

              {editMode && (
                <button
                  onClick={onHandleStudents}
                  className="edit_student_btn border-none yellow white"
                >
                  {/* 저장 */}
                  {t(`Save`)}
                </button>
              )}
            </div>
            {editMode && (
              <div className="text-center mt-10">
                <p style={{ fontSize: 12 }} className="card-text-red">
                  {/* * '저장'을 눌러야 학생코드가 생성됩니다. */}
                  {t(`You must press Save to generate...`)}
                </p>
                <p style={{ fontSize: 12 }} className="card-text-red">
                  {/* * 번호를 바꾸고 '저장'하면 번호순에 따라 자동 재정렬됩니다. */}
                  {t(`Renumber them and Save to automatically...`)}
                </p>
              </div>
            )}
          </div>
        </Modal>
      )}
    </div>
  );
};

export default Students;
