import React, { useState, useRef, useEffect, useContext } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { IMainContext, IProject, IProjectOfStudent, IStudent } from "../interface/Interface";
import ReactQuill from "react-quill";
import check2 from "../images/check2.png";
import back from "../images/arrow-left.png";
import {
  getByToken,
  postUpdate,
  postNotification,
  getChecklist,
  getCos,
  putModifyCos,
  putCheckCos,
  putCancelCheckCos,
  putInitializeChecklist,
  getStudents,
} from "../common/Action";
import PopupAlbum from "../component/PopupAlbum";

import { MainContext } from "../common/Context";
import PopupModal from "../component/PopupModal";
import PopupQuill from "../component/PopupQuill";
import Student from "./Student";
import Modal from "../component/Modal";
import { t } from "i18next";

const ChecklistDetail: React.FC = () => {
  const fileRef = useRef<HTMLInputElement>(null);
  const popupRef = useRef<HTMLDivElement>(null);
  const history = useHistory();
  const params: any = useParams();
  const location: any = useLocation();
  const [studentData, setStudentData] = useState<IStudent[]>([]); //프로젝트 안에 학생 데이터
  const [studentAll, setStudentAll] = useState<IStudent[]>([]); //모든 학생
  const [files, setFiles] = useState<any[]>([]);
  const [fileUrls, setFileUrls] = useState<string[]>([]);
  const [popup, setPopup] = useState<boolean>(false);
  const [studentCheckWait, setStudentCheckWait] = useState<boolean>(false);
  const [blockSubmit, setBlockSubmit] = useState<boolean>(false);
  const context = useContext<IMainContext>(MainContext);
  const [checklistInfo, setChecklistInfo] = useState<any>([]);
  const [students, setStudents] = useState<any>([]);
  const [checklistDetail, setChecklistDetail] = useState<string>("");
  const [checklistScore, setChecklistScore] = useState<string>("");
  const [clickedStudentInfo, setClickedStudentInfo] = useState<any>({});
  const [checklistEditMode, setchecklistEditMode] = useState<boolean>(false);
  const [save, setSave] = useState<boolean>(false);
  const [hidden, setHidden] = useState<boolean>(false);
  const [hiddenChecklistInfoPopup, setHiddenChecklistInfoPopup] = useState<boolean>(false);
  const [hiddenCosPopup, setHiddenCosPopup] = useState<boolean>(false);
  const [onFolder, setOnFolder] = useState<boolean>(false);

  useEffect(() => {
    init();
    return () => {};
  }, []);

  const init = async (): Promise<void> => {
    const checklistResult: any = await getChecklist(params.checklistId);
    const checklistStudentResult: any = await getCos(params.checklistId);

    if (checklistResult.onFolder) setOnFolder(true);
    if (location.pathname.includes("hidden")) setHidden(true);
    checklistStudentResult.sort((a: any, b: any) => a.studentInfo[0].num - b.studentInfo[0].num);
    setChecklistInfo(checklistResult);
    setStudents(checklistStudentResult);
  };

  const handleFile = (e: any) => {
    if (e.target.files) {
      if (e.target.files[0].type.split("/")[0] === "image") {
        let fileTemp = [...files];
        fileTemp.push(e.target.files[0]);
        setFiles(fileTemp);
        let fileUrlTemp = [...fileUrls];
        fileUrlTemp.push(URL.createObjectURL(e.target.files[0]));
        setFileUrls(fileUrlTemp);
      } else {
        // alert("이미지만 첨부해주세요!");
        alert(t(`Please only attach images`));
      }
    } else {
      return;
    }
  };

  const handleStudentChecked = (i: number) => {
    setStudentCheckWait(true);
    const temp = [...studentAll];
    temp[i].checked = !temp[i].checked;
    setStudentAll(temp);
    setStudentCheckWait(false);
  };

  const handleClickOutside = (e: any): void => {
    e.preventDefault();
    setPopup(false);
    setHiddenCosPopup(false);
  };

  const handleEditChecklist = async (): Promise<void> => {
    if (!hidden) {
      setchecklistEditMode(true);
    } else {
      let studentAllResult: any = await getStudents(params.classId);
      for (let i = 0; i < studentAllResult?.length; i++) {
        for (let k = 0; k < students?.length; k++) {
          if (studentAllResult[i]?.code === students[k]?.code) {
            studentAllResult[i].checklistCheck = !students[k].hide || students[k].check;
            studentAllResult[i].checklistStudentId = students[k]._id;
          }
        }
      }

      setStudentAll(studentAllResult);
      setHiddenChecklistInfoPopup(true);
    }
  };

  const handlePopup = async (student: any, e: any): Promise<void> => {
    e.preventDefault();

    setClickedStudentInfo(student);
    if (!hidden) {
      setPopup(true);
    } else {
      setHiddenCosPopup(true);
    }
  };

  const handleEasyCheck = async (student: any, e: any): Promise<void> => {
    e.stopPropagation();
    await putCheckCos(student._id, "complete");

    init();
  };

  const handleSave = async (): Promise<void> => {
    const update: any = await putModifyCos({
      id: clickedStudentInfo._id,
      description: clickedStudentInfo.description,
    });

    if (update) setSave(true);

    setTimeout(() => {
      setSave(false);
    }, 500);

    init();
  };

  const handleSubmitChecklist = async (): Promise<void> => {
    let state = clickedStudentInfo.state === "" ? "complete" : clickedStudentInfo.state;
    await putCheckCos(clickedStudentInfo._id, state);
    await handleSave();
    setPopup(false);
    init();
  };

  const handleUnSubmit = async (): Promise<void> => {
    await putCancelCheckCos(clickedStudentInfo._id);

    setChecklistScore("");
    setChecklistDetail("");

    setPopup(false);

    init();
  };

  const handleResetAll = async (): Promise<void> => {
    // const confirm = window.confirm("모든 간편기록이 삭제됩니다. 단, 상세 기록은 유지됩니다.");
    const confirm = window.confirm(t(`All simple records will be deleted...`));
    if (confirm) {
      const deleteResult: any = await putInitializeChecklist(
        students[0]?.checklistId,
        params.classId,
      );
      init();
    } else {
      return;
    }
  };

  return (
    <>
      <div className="col-md-12 category_container">
        <div className="display-f justify-between align-center mb-33 pl-10 pr-10">
          <div className="display-f align-center  mb-9">
            <img
              onClick={() => history.goBack()}
              src={back}
              className="mr-20 cursor"
              style={{ marginLeft: -10, width: 45, height: "auto" }}
            />
            <p className="font-23 font-700">{checklistInfo?.title}</p>
            {!hidden && (
              <button
                onClick={handleResetAll}
                className="ml-30 white pt-5 pb-5 pl-36 pr-36 font-16 background-green"
                style={{ borderRadius: 7, border: "none" }}
              >
                {/* 초기화 */}
                {t(`Initialization`)}
              </button>
            )}
          </div>
          <div>
            <button onClick={handleEditChecklist} className="project_control_btn mr-28">
              {/* {hidden ? "항목 정보" : "항목 정보 수정"} */}
              {hidden ? t(`Item Information`) : t(`Edit Item Information`)}
            </button>
          </div>
        </div>
        <div className="display-f flex-wrap">
          {students.map((student: any, i: number) => (
            <>
              {student.state === "" ? (
                <div
                  onClick={(e) => handlePopup(student, e)}
                  key={i}
                  className="display-ib pl-10 pr-10 position-r"
                >
                  <div
                    className={`card project_inner_card background-green direction-column  ${
                      hidden ? "cursor" : "cursor-none"
                    }`}
                  >
                    <div className="display-f mb-12">
                      <p className="font-15 font-700 letter-spacing">
                        {student?.studentInfo[0].name}
                      </p>
                      &nbsp;
                    </div>
                    {!hidden && (
                      <>
                        <button
                          onClick={(e) => handlePopup(student, e)}
                          className="card_check_btn font-10 mb-8"
                        >
                          {/* 상세 기록 */}
                          {t(`Detailed history`)}
                        </button>
                        <button
                          onClick={(e) => {
                            handleEasyCheck(student, e);
                          }}
                          className="card_check_btn font-10 background-red-btn white"
                        >
                          {/* 체크 */}
                          {t(`Check`)}
                        </button>
                      </>
                    )}
                  </div>
                </div>
              ) : (
                <div
                  onClick={(e) => handlePopup(student, e)}
                  className="display-ib pl-10 pr-10 position-r"
                >
                  <div className="card project_inner_card background-gray direction-column">
                    <div className="display-f direction-column justify-between studentOfProject_card mb-9">
                      <div className="display-f" style={{ paddingLeft: 28 }}>
                        <p className="font-15 color-dark font-700">
                          {student.studentInfo[0]?.name}
                        </p>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          padding: "0 20px",
                        }}
                      >
                        <p className="color-red font-30 font-700 word-break">
                          {students.length !== 0 && student.state !== "complete" && student.state}
                        </p>
                        {student.state === "complete" && (
                          <img src={check2} style={{ width: 50, height: 50, marginTop: 10 }} />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </>
          ))}
        </div>

        {/* popup */}
        {popup && (
          <div className="popup">
            <div className="modal-overlay" onClick={handleClickOutside}></div>
            <div
              className="project_add_popup scroll popup_column_sb"
              style={{ backgroundColor: "rgba(246, 248, 250)" }}
            >
              <div>
                <h2 className="text-center">
                  {checklistInfo.title}{" "}
                  <span style={{ fontSize: 14 }}>({clickedStudentInfo.studentInfo[0]?.name})</span>
                </h2>
                <div
                  style={{
                    paddingTop: 20,
                    padding: "20px 0 20px 10px",
                    backgroundColor: "#fff",
                  }}
                >
                  <div dangerouslySetInnerHTML={{ __html: checklistInfo.description }} />
                  <div className="display-f" style={{ flexWrap: "wrap" }}>
                    {checklistInfo?.file.map((url: string, i: number) => (
                      <img key={i} src={url} style={{ width: "48%" }} />
                    ))}
                  </div>
                </div>
                <div>
                  <div className="display-f align-center">
                    <h3>
                      {/* 상세 기록 */}
                      {t(`Detailed history`)}
                    </h3>
                    <button onClick={handleSave} className="checklist-save-btn ml-15">
                      {/* 기록 저장 */}
                      {t(`Save records`)}
                    </button>
                    {save && (
                      <p className="ml-7" style={{ color: "red", fontSize: 10 }}>
                        {/* 기록이 저장되었습니다. */}
                        {t(`Your records have been saved`)}
                      </p>
                    )}
                    {!save && (
                      <p className="ml-7" style={{ color: "#979797", fontSize: 10 }}>
                        {/* 기록 저장을 누르지 않으면, 상세 기록이 저장되지 않습니다. */}
                        {t(`If you don't tap Save history...`)}
                      </p>
                    )}
                  </div>
                  <textarea
                    value={clickedStudentInfo.description}
                    onChange={(e) =>
                      setClickedStudentInfo((prev: any) => {
                        return {
                          ...prev,
                          description: e.target.value,
                        };
                      })
                    }
                    rows={16}
                    className="popup-checklist-textarea"
                  />
                </div>
              </div>

              <div>
                <div style={{ width: "100%", textAlign: "center" }}>
                  {/* <button className="score-btn">성적</button> */}
                  {/* <button onClick={handleUnSubmit} className="unsubmit-btn background-green mt-10">
                    확인 취소
                  </button> */}
                  {/* <p
                    className="card-text-red"
                    style={{ paddingTop: 5, marginTop: 10, fontSize: 12 }}
                  >
                    상세 기록은 유지됩니다
                  </p> */}
                </div>

                <div className="pt-20 pb-20 display-f align-center">
                  <p className="mr-20">
                    {/* 간단 메모 */}
                    {t(`Brief memo`)}
                  </p>
                  <input
                    value={clickedStudentInfo.state === "complete" ? "" : clickedStudentInfo.state}
                    className="popup-scrore-input"
                    onChange={(e) => {
                      if (e.target.value.length > 10) return;
                      setClickedStudentInfo((prev: any) => {
                        return {
                          ...prev,
                          state: e.target.value,
                        };
                      });
                    }}
                  />
                </div>

                <div className="display-f justify-center mt-20 mb-10">
                  <button
                    onClick={handleSubmitChecklist}
                    className="project__sign__btn background-red-btn mr-10 color-white"
                  >
                    {/* 체크 */}
                    {t(`Check`)}
                  </button>
                  <button
                    onClick={handleUnSubmit}
                    className="project__sign__btn background-green-btn color-white"
                  >
                    {/* 체크 취소 */}
                    {t(`Cancel Check`)}
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}

        {hiddenCosPopup && (
          <div className="popup">
            <div className="modal-overlay" onClick={handleClickOutside}></div>
            <div
              className="project_add_popup scroll popup_column_sb"
              style={{ backgroundColor: "rgba(246, 248, 250)" }}
            >
              <div>
                <h2 className="text-center">
                  {checklistInfo.title}{" "}
                  <span style={{ fontSize: 14 }}>({clickedStudentInfo.studentInfo[0]?.name})</span>
                </h2>
                <div
                  style={{
                    paddingTop: 20,
                    padding: "20px 0 20px 10px",
                    backgroundColor: "#fff",
                  }}
                >
                  <div dangerouslySetInnerHTML={{ __html: checklistInfo.description }} />
                  {checklistInfo.file.length !== 0 && (
                    <img src={checklistInfo.file[0]} style={{ width: "40%" }} />
                  )}
                </div>
                <div>
                  <div className="display-f align-center">
                    <h3>
                      {/* 상세 기록 */}
                      {t(`Detailed history`)}
                    </h3>
                  </div>

                  <textarea
                    readOnly
                    value={clickedStudentInfo.description}
                    rows={16}
                    className="popup-checklist-textarea"
                  />
                </div>
              </div>

              <div>
                <div className="pt-20 pb-20 display-f align-center">
                  <p className="mr-20">
                    {/* 간단 메모 */}
                    {t(`Brief memo`)}
                  </p>
                  <input
                    readOnly
                    value={clickedStudentInfo.state === "complete" ? "" : clickedStudentInfo.state}
                    className="popup-scrore-input"
                  />
                </div>
              </div>
            </div>
          </div>
        )}

        {hiddenChecklistInfoPopup && (
          <Modal
            show={hiddenChecklistInfoPopup}
            onClose={() => {
              setHiddenChecklistInfoPopup(false);
            }}
          >
            <div
              className="stack_report_container"
              style={{
                width: "36vw",
                paddingTop: 30,
                height: "80vh",
                overflow: "auto",
                backgroundColor: "#fff",
              }}
            >
              <div>
                <p className="font-23 mb-26 text-center">
                  {/* 항목 정보 */}
                  {t(`Item Information`)}
                </p>
                <p className="color-light-black mb-8 font-15">
                  {/* 제목 */}
                  {t(`Title`)}
                </p>

                <div className="display-f align-center mb-22">
                  <div className="font-500">{checklistInfo.title}</div>
                </div>

                <div className="mb-22">
                  <p className="color-light-black mb-8 font-15">
                    {/* 폴더 */}
                    {t(`Folder`)}
                  </p>
                  {/* <div>{!checklistInfo.onFolder && "폴더 미지정"}</div> */}
                  <div>{!checklistInfo.onFolder && t(`Unspecified folder`)}</div>
                </div>

                <p className="color-light-black font-15 mb-8">
                  {/* 내용 */}
                  {t(`Detail`)}
                </p>
                <div
                  style={{ border: "1px solid #ececec", minHeight: 200, padding: 10 }}
                  dangerouslySetInnerHTML={{ __html: checklistInfo.description }}
                ></div>

                <div className="display-f" style={{ flexWrap: "wrap" }}>
                  {checklistInfo.file?.map((data: string, index: number) => (
                    <div style={{ width: "50%" }} key={index}>
                      <img src={data} className="img-add mb-10 mt-10" alt="file" />
                    </div>
                  ))}
                </div>
                <div style={{ marginTop: 22 }}>
                  <p className="color-light-black mb-8 font-15">
                    {/* 지정된 학생 */}
                    {t(`Designated student`)}
                  </p>
                </div>

                {studentAll
                  .sort((a: any, b: any) => a.num - b.num)
                  .map((data: any, index: number) => (
                    <div key={index} className="managing__options__contents position-r">
                      <p className="mt-15 mb-15 font-15">{data.name}</p>
                      <input
                        onChange={() => handleStudentChecked(index)}
                        type="checkbox"
                        id={JSON.stringify(index)}
                        className="display-n"
                      />
                      <div>
                        <label className="target_label cursor" htmlFor={JSON.stringify(index)}>
                          {data.checklistCheck && <span className="studentList_check"></span>}
                        </label>
                      </div>
                    </div>
                  ))}

                <div className="popup__additional__project mb-30"></div>
              </div>
            </div>
          </Modal>
        )}
        {checklistEditMode && !hidden && (
          <PopupQuill
            folderId={checklistInfo.folderId && checklistInfo.folderId}
            checklistInfo={checklistInfo}
            cos={students}
            openModal={checklistEditMode}
            setOpenModal={setchecklistEditMode}
            init={init}
          />
        )}
      </div>
    </>
  );
};

export default ChecklistDetail;
