import React, { useState, useEffect, useContext } from "react";
import { MainContext } from "../common/Context";
import { IProject, IMainContext } from "../interface/Interface";
import { Link, Route, useParams } from "react-router-dom";
import OutsideClickHandler from "react-outside-click-handler";
import down from "../images/down.png";
import search from "../images/search.png";
import {
  deletelist,
  deletelist2,
  getByToken,
  getCheckLists,
  getChecklistsHide,
  getProjects,
  postDelete,
  postDeleteMany,
  postUpdate,
  putHideChecklist,
  putHideProject,
} from "../common/Action";
import { isVariableDeclarationList } from "typescript";
import { t } from "i18next";

const HiddenChecklist: React.FC = () => {
  const context = useContext<IMainContext>(MainContext);
  const params: any = useParams();
  const [cardData, setCardData] = useState<IProject[]>([
    // { title: "숨김과제", _id: "1", checked: false, state: "" },
    // { title: "숨김과제", _id: "2", checked: false, state: "" },
    // { title: "숨김과제", _id: "3", checked: false, state: "" },
  ]);
  const [originalCards, setOriginalCards] = useState<IProject[]>([]);
  const [filter, setFilter] = useState<string[]>([
    t(`Unmarked`), // 미확인 순
    t(`Not Submitted`), // 미제출 순
    t(`Oldest to Newest`), // 생성 오래된 순
    t(`Alphabetical A-Z`), // 가나다 순
  ]);
  const [filterSelected, setFilterSelected] = useState<string>(t(`Unmarked`)); // 미확인 순
  const [filterPopup, setFilterPopup] = useState<boolean>(false);
  const [projectEdit, setProjectEdit] = useState<boolean>(false);
  const [allSelected, setAllselected] = useState<boolean>(false);
  const [searchInput, setSearchInput] = useState<string>("");
  const [tags, setTags] = useState<any[]>([{}, {}, {}, {}, {}, {}, {}, {}, {}, {}]);

  useEffect(() => {
    context.handleStateChange("class", params.id);
    init();
    return () => {};
  }, []);

  const init = async (): Promise<void> => {
    const result: any = await getChecklistsHide(params.classId, "created", 1);

    // console.log(result);
    setOriginalCards(result);
    setCardData(result);
  };

  const handleFilterSelect = async (index: number): Promise<void> => {
    let temp = "";
    temp = filter[index];
    setFilterSelected(temp);
    let filterData: any = [];
    if (filter[index] === t(`Unmarked`)) {
      // 미확인 순
      filterData = await getProjects(params.classId, true, "confirmOrder", 1);
    } else if (filter[index] === t(`Order of non submission`)) {
      // 미제출 순
      filterData = await getProjects(params.classId, true, "status", -1);
    } else if (filter[index] === t(`Oldest to Newest`)) {
      // 생성 날짜 순
      filterData = await getProjects(params.classId, true, "created", 1);
    } else if (filter[index] === t(`Alphabetical A-Z`)) {
      // 가나다 순
      filterData = await getProjects(params.classId, true, "title", 1);
    }
    setCardData(filterData);
    setFilterPopup(false);
  };

  const handleSelectAll = (): void => {
    const temp = [...cardData];
    for (let i = 0; i < temp.length; i++) {
      if (!allSelected) {
        temp[i].checked = true;
      } else {
        temp[i].checked = false;
      }
    }
    setAllselected((prev) => !prev);
    setCardData(temp);
  };

  const handleCheckCard = (index: number): void => {
    const temp = [...cardData];
    for (let i = 0; i < temp.length; i++) {
      if (index === i) {
        temp[index].checked = !temp[index].checked;
      }
    }
    setCardData(temp);
  };

  const handleProjectReturn = async (): Promise<void> => {
    let folderIds: string[] = [];
    let checklistIds: string[] = [];

    for (let i in cardData) {
      if (cardData[i].checked && cardData[i].isFolder) {
        folderIds.push(cardData[i]._id);
      }
      if (cardData[i].checked && !cardData[i].isFolder) {
        checklistIds.push(cardData[i]._id);
      }
    }

    const result = await putHideChecklist({
      checklistIds,
      folderIds,
      hide: false,
      classId: params.classId,
    });

    // alert("원래 자리로 이동되었습니다!");
    alert(t(`It has been moved to its original spot`));
    setProjectEdit(false);
    init();
  };

  const handleDeleteProject = async (): Promise<void> => {
    const confirm = window.confirm(
      // "⛔️ 삭제 시 상세기록 및 간편기록이 영구히 삭제됩니다. (복구 절대 불가) \n 진행하시겠습니까?",
      t(`Deleting will permanently delete your Simple History...`),
    );
    if (confirm) {
      let tempIds = [];
      let tempFolderIds = [];
      for (let i in cardData) {
        if (cardData[i].checked && !cardData[i].isFolder) {
          tempIds.push(cardData[i]._id);
        }
        if (cardData[i].checked && cardData[i].isFolder) {
          tempFolderIds.push(cardData[i]._id);
        }
      }

      await deletelist2(tempIds, tempFolderIds, params.classId);
      init();

      // alert("과제가 삭제되었습니다!");
      alert(t(`Assignment deleted`));
      setProjectEdit(false);
      context.handleStateChange("class", "");
      init();
    } else {
      return;
    }
  };

  const handleSearch = (e: any) => {
    setSearchInput(e.target.value);
    const filterData: IProject[] = originalCards.filter(
      (el: IProject, i: number) =>
        el.title.includes(e.target.value) || el.description?.includes(e.target.value),
    );
    if (e.target.value === "") setCardData(originalCards);
    else setCardData(filterData);
  };

  return (
    <div className="col-md-12 category_container">
      {projectEdit ? (
        <>
          <div className="display-f justify-between align-center mb-33 pl-10 pr-10">
            <div className="display-f align-center">
              <button onClick={handleSelectAll} className="select_all_btn mr-10 position-r">
                {allSelected && (
                  <span className={`check__inside select_all position-a bg-checked`}></span>
                )}
              </button>
              <p className="font-16 mt-9 mb-10 mr-150">{t(`Select All`)}</p>
              <div>
                <button
                  onClick={handleProjectReturn}
                  className="border_blue text_blue select_option_btn mr-20"
                >
                  {/* 원래 자리로 */}
                  {t(`Restore`)}
                </button>
                <button
                  onClick={handleDeleteProject}
                  className="border_red text_red select_option_btn"
                >
                  {/* 삭제 */}
                  {t(`Delete`)}
                </button>
              </div>
            </div>
            <div>
              <button onClick={() => setProjectEdit(false)} className="select_submit_btn">
                {/* 취소 */}
                {t(`Cancel`)}
              </button>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="display-f mb-20">
            <div className="col-md-10">
              <div className="display-f align-center justify-between position-r">
                <div className="display-f">
                  <p className="font-23 font-700 mr-10">{t(`Archived Checklist`)}</p>
                  <p className="font-23">{t(`Archived assignments`)}</p>
                </div>
              </div>
              <p className="card-text-red">
                {/* 숨긴 상태에서는 체크리스트의 열람만 가능합니다. */}
                {t(`Archived checklists are limited to view only access`)}
              </p>
            </div>
            <div className="col-md-12 pr-10">
              <div className="display-f align-center" style={{ justifyContent: "flex-end" }}>
                <form
                  className="position-r mr-10"
                  // onSubmit={(e: any) => handleSearch(e)}
                  // action={`/projects/${searchInput}`}
                >
                  <img
                    // onClick={(e: any) => handleSearch(e)}
                    className="search_icon position-a cursor"
                    src={search}
                    alt="search"
                  />
                  <input
                    value={searchInput}
                    onChange={handleSearch}
                    type="text"
                    className="category_searchInput pl-50 pr-10"
                  />
                </form>
                {/* filter */}
                {/* <OutsideClickHandler onOutsideClick={() => setFilterPopup(false)}>
                  <button
                    onClick={() => setFilterPopup((prev) => !prev)}
                    className="filter_btn text-left pl-17 pr-12 display-f justify-between align-center mr-10"
                  >
                    {filterSelected} <img className="filter_icon" src={down} alt="down" />
                  </button>
                  {filterPopup && (
                    <div className="position-a filter_contents_hidden">
                      {filter.map((data: string, index: number) => (
                        <div
                          onClick={() => handleFilterSelect(index)}
                          className="filter_inner cursor"
                          key={index}
                        >
                          <p className="pl-17 pt-11 pb-11 font-14">{data}</p>
                        </div>
                      ))}
                    </div>
                  )}
                </OutsideClickHandler> */}
                <button onClick={() => setProjectEdit(true)} className="select_btn">
                  {/* 선택 */}
                  {t(`Select`)}
                </button>
              </div>
            </div>
          </div>
        </>
      )}

      <div className="display-f flex-wrap">
        {cardData?.map((data: any, index: number) => {
          if (projectEdit && !data.isFolder) {
            return (
              <div key={index} className="display-ib pl-10 pr-10 position-r cursor">
                <button
                  onClick={() => handleCheckCard(index)}
                  style={{ border: "none", cursor: "pointer" }}
                  className={`card_checklist_normal project_inner_card border-14 padding-0 justify-center pl-15 pr-15 ${data?.status}`}
                >
                  <div className="display-b mb-9 font-15 color-dark font-700 text-center word-break">
                    {data.title}
                  </div>
                  <span className="position-a check cursor">
                    {data.checked && (
                      <span className={`check__inside position-a bg-checked`}></span>
                    )}
                  </span>
                </button>
              </div>
            );
          }

          if (projectEdit && data.isFolder) {
            return (
              <div key={index} className="display-ib pl-10 pr-10 position-r cursor">
                <button
                  onClick={() => handleCheckCard(index)}
                  style={{ border: "none", cursor: "pointer" }}
                  className={`card_checklist project_inner_card border-14 padding-0 justify-center pl-15 pr-15 ${data?.status}`}
                >
                  <div className="display-b mb-9 font-15 color-dark font-700 text-center word-break">
                    {data.title}
                  </div>
                  <span className="position-a check cursor">
                    {data.checked && (
                      <span className={`check__inside position-a bg-checked`}></span>
                    )}
                  </span>
                </button>
              </div>
            );
          }

          if (!projectEdit && !data.searchHide && !data.isFolder) {
            return (
              <Link key={index} to={`/page/hiddens/checklistdetail/${params.classId}/${data._id}`}>
                <div key={index} className="display-ib pl-10 pr-10">
                  <div
                    className={`card_checklist_normal project_inner_card justify-center padding-0 pl-15 pr-15 ${data.status}`}
                  >
                    <div
                      className={`word-break display-b mb-9 font-15 font-700 text-center color-${
                        data.color === "green" ? "white" : "dark"
                      }`}
                    >
                      {data.title}
                    </div>
                  </div>
                </div>
              </Link>
            );
          }

          if (!projectEdit && data.isFolder) {
            return (
              <Link key={index} to={`/page/hiddens/folder/checklist/${params.classId}/${data._id}`}>
                <div key={index} className="display-ib pl-10 pr-10">
                  <div
                    className={`border-14 card_checklist project_inner_card justify-center padding-0 pl-15 pr-15 ${data.status}`}
                  >
                    <div
                      className={`word-break display-b mb-9 font-15 font-700 text-center color-${
                        data.color === "green" ? "white" : "dark"
                      }`}
                    >
                      {data.title}
                    </div>
                  </div>
                </div>
              </Link>
            );
          }
        })}
      </div>
    </div>
  );
};

export default HiddenChecklist;
