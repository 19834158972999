import React, { useState, useEffect, useContext } from "react";
import { MainContext } from "../common/Context";
import { IProject, IMainContext } from "../interface/Interface";
import { Link, Route, useParams } from "react-router-dom";
import OutsideClickHandler from "react-outside-click-handler";
import down from "../images/down.png";
import search from "../images/search.png";
import {
  deletelist,
  getByToken,
  getCategorys,
  getProjects,
  postDelete,
  postDeleteMany,
  postUpdate,
  putHideProject,
} from "../common/Action";
import { isVariableDeclarationList } from "typescript";
import { t } from "i18next";

const Hidden: React.FC = () => {
  const context = useContext<IMainContext>(MainContext);
  const params: any = useParams();
  const [cardData, setCardData] = useState<IProject[]>([
    // { title: "숨김과제", _id: "1", checked: false, state: "" },
    // { title: "숨김과제", _id: "2", checked: false, state: "" },
    // { title: "숨김과제", _id: "3", checked: false, state: "" },
  ]);
  const [originalCards, setOriginalCards] = useState<IProject[]>([]);
  const [filter, setFilter] = useState<string[]>([
    t(`Unmarked`), // 미확인 순
    t(`Order of non submission`), // 미제출 순
    t(`Oldest to Newest`), // 생성 오래된 순
    t(`Alphabetical A-Z`), // 가나다 순
  ]);
  const [filterSelected, setFilterSelected] = useState<string>(t(`Unmarked`)); // 미확인 순
  const [filterPopup, setFilterPopup] = useState<boolean>(false);
  const [projectEdit, setProjectEdit] = useState<boolean>(false);
  const [allSelected, setAllselected] = useState<boolean>(false);
  const [searchInput, setSearchInput] = useState<string>("");
  const [tags, setTags] = useState<any[]>([{}, {}, {}, {}, {}, {}, {}, {}, {}, {}]);
  // const [nodata, setNoData] = useState<boolean>(false);

  useEffect(() => {
    context.handleStateChange("class", params.id);
    init();
    return () => {};
  }, []);

  const init = async (): Promise<void> => {
    const result: any = await getProjects(params.classId, true);
    const tagsResult: any = await getCategorys(params.classId);

    if (tagsResult.length !== 0) setTags(tagsResult[0]?.tags);
    setOriginalCards(result);
    setCardData(result);
  };

  const handleFilterSelect = async (index: number): Promise<void> => {
    let temp = "";
    temp = filter[index];
    setFilterSelected(temp);
    let filterData: any = [];
    if (filter[index] === t(`Unmarked`)) {
      // 미확인 순
      filterData = await getProjects(params.classId, true, "confirmOrder", 1);
    } else if (filter[index] === t(`Order of non submission`)) {
      // 미제출 순
      filterData = await getProjects(params.classId, true, "status", -1);
    } else if (filter[index] === t(`Oldest to Newest`)) {
      // 생성 오래된 순
      filterData = await getProjects(params.classId, true, "created", 1);
    } else if (filter[index] === t(`Alphabetical A-Z`)) {
      // 가나다 순
      filterData = await getProjects(params.classId, true, "title", 1);
    }
    setCardData(filterData);
    setFilterPopup(false);
  };

  const handleSelectAll = (): void => {
    const temp = [...cardData];
    for (let i = 0; i < temp.length; i++) {
      if (!allSelected) {
        temp[i].checked = true;
      } else {
        temp[i].checked = false;
      }
    }
    setAllselected((prev) => !prev);
    setCardData(temp);
  };

  const handleCheckCard = (index: number): void => {
    const temp = [...cardData];
    for (let i = 0; i < temp.length; i++) {
      if (index === i) {
        temp[index].checked = !temp[index].checked;
      }
    }
    setCardData(temp);
  };

  const handleProjectReturn = async (): Promise<void> => {
    let temp: string[] = [];
    for (let i in cardData) {
      if (cardData[i].checked) {
        temp.push(cardData[i]._id);
      }
    }

    const result = await putHideProject({
      token: localStorage.getItem("token"),
      idList: temp,
      hide: false,
    });

    // alert("원래 자리로 이동되었습니다!");
    alert(t(`It has been moved to its original spot`));
    setProjectEdit(false);
    init();
  };

  const handleDeleteProject = async (): Promise<void> => {
    const confirm = window.confirm(
      // "⛔️ 정말 삭제하시겠습니까? \n 과제 삭제 시 학생이 보냈던 모든 자료와 해당 과제로 부과되었던 🍪`쿠키` 기록이 영구히 사라집니다. (복구 불가) 진행 하시겠습니까?",
      t(`Are you sure you want to delete this...`),
    );
    if (confirm) {
      for (let i in cardData) {
        let idList = [];
        if (cardData[i].checked) {
          idList.push(cardData[i]._id);
        }

        await deletelist("projects", idList);
      }
      // alert("과제가 삭제되었습니다!");
      alert(t(`Assignment deleted`));
      setProjectEdit(false);
      context.handleStateChange("class", "");
      init();
    } else {
      return;
    }
  };

  const handleSearch = (e: any) => {
    setSearchInput(e.target.value);
    const filterData: IProject[] = originalCards.filter(
      (el: IProject, i: number) =>
        el.title.includes(e.target.value) || el.description?.includes(e.target.value),
    );
    if (e.target.value === "") setCardData(originalCards);
    else setCardData(filterData);
  };

  const handleFilterTag = (tag: any) => {
    const tempTags = [...tags];
    const findTagIdx = tags.findIndex((el: any) => el === tag);
    tempTags[findTagIdx].tagSelected = !tags[findTagIdx].tagSelected;

    const selectedTags = tempTags.filter((item: any) => item.tagSelected);
    if (selectedTags.length === 0) {
      setCardData(originalCards);
      return;
    }

    let tempFilterData = [];
    for (let i = 0; i < originalCards?.length; i++) {
      let exist = false;
      for (let k = 0; k < originalCards[i]?.tags?.length; k++) {
        for (let h = 0; h < selectedTags?.length; h++) {
          if (!exist && tags[originalCards[i]?.tags[k]] === selectedTags[h]) {
            tempFilterData.push(originalCards[i]);
            exist = true;
          }
        }
      }
    }

    setTags(tempTags);
    setCardData(tempFilterData);
  };

  return (
    <div className="col-md-12 category_container">
      {projectEdit ? (
        <>
          <div className="display-f justify-between align-center mb-33 pl-10 pr-10">
            <div className="display-f align-center">
              <button onClick={handleSelectAll} className="select_all_btn mr-10 position-r">
                {allSelected && (
                  <span className={`check__inside select_all position-a bg-checked`}></span>
                )}
              </button>
              <p className="font-16 mt-9 mb-10 mr-150">
                {/* 모두 선택 */}
                {t(`Select All`)}
              </p>
              <div>
                <button
                  onClick={handleProjectReturn}
                  className="select_option_btn border_blue mr-20 text_blue font-500"
                >
                  {/* 원래 자리로 / 복원 */}
                  {t(`Restore`)}
                </button>
                <button
                  onClick={handleDeleteProject}
                  className="border_red text_red select_option_btn"
                >
                  {/* 삭제 */}
                  {t(`Delete`)}
                </button>
              </div>
            </div>
            <div>
              <button onClick={() => setProjectEdit(false)} className="select_submit_btn">
                {/* 취소 */}
                {t(`Cancel`)}
              </button>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="display-f mb-20">
            <div className="col-md-10">
              <div className="display-f align-center justify-between position-r">
                <div className="display-f">
                  <p className="font-23 font-700 mr-10">
                    {/* 숨긴 과제 보관함 */}
                    {t(`Hidden Assignment Archive`)}
                  </p>
                  <p className="font-23">
                    {/* (임시 휴지통) */}
                    {t(`Temporary trash`)}
                  </p>
                </div>
              </div>
              <p className="card-text-red">
                {/* 숨김 상태에서는 알림장의 열람만 가능합니다 */}
                {t(`Archived notices are limited to view only access.`)}
              </p>
            </div>
            <div className="col-md-12 pr-10">
              <div className="display-f align-center" style={{ justifyContent: "flex-end" }}>
                <form
                  className="position-r mr-10"
                  // onSubmit={(e: any) => handleSearch(e)}
                  // action={`/projects/${searchInput}`}
                >
                  <img
                    // onClick={(e: any) => handleSearch(e)}
                    className="search_icon position-a cursor"
                    src={search}
                    alt="search"
                  />
                  <input
                    value={searchInput}
                    onChange={handleSearch}
                    type="text"
                    className="category_searchInput pl-50 pr-10"
                  />
                </form>
                <OutsideClickHandler onOutsideClick={() => setFilterPopup(false)}>
                  <button
                    onClick={() => setFilterPopup((prev) => !prev)}
                    className="filter_btn text-left pl-17 pr-12 display-f justify-between align-center mr-10"
                  >
                    {filterSelected} <img className="filter_icon" src={down} alt="down" />
                  </button>
                  {filterPopup && (
                    <div className="position-a filter_contents_hidden">
                      {filter.map((data: string, index: number) => (
                        <div
                          onClick={() => handleFilterSelect(index)}
                          className="filter_inner cursor"
                          key={index}
                        >
                          <p className="pl-17 pt-11 pb-11 font-14">{data}</p>
                        </div>
                      ))}
                    </div>
                  )}
                </OutsideClickHandler>
                <button onClick={() => setProjectEdit(true)} className="select_btn">
                  {/* 선택 */}
                  {t(`Select`)}
                </button>
              </div>
            </div>
          </div>
        </>
      )}

      <div className="mb-20 pl-10 pr-10">
        <div style={{ width: "30%" }}>
          {tags?.map((tag: any, i: number) => (
            <>
              {!tag.tagName && (
                <button
                  className={`tag_btn_block gray ${i % 5 === 4 ? "" : "mr-10"} ${
                    i > 4 ? "mt-10" : ""
                  }`}
                >
                  <p style={{ color: "#ececec" }}>+</p>
                </button>
              )}
              {tag.tagName && (
                <button
                  onClick={() => handleFilterTag(tag)}
                  className={`tag_btn_fill ${tag.tagSelected ? "tag_btn_selected" : ""} ${
                    i % 5 === 4 ? "" : "mr-10"
                  } ${i > 4 ? "mt-10" : ""}`}
                >
                  {tag.tagName}
                </button>
              )}
            </>
          ))}
        </div>
      </div>

      <div className="display-f flex-wrap">
        {cardData?.map((data: IProject, index: number) => {
          if (projectEdit) {
            return (
              <div key={index} className="display-ib pl-10 pr-10 position-r">
                <div
                  onClick={() => handleCheckCard(index)}
                  className={`card project_inner_card padding-0 justify-center cursor ${
                    data.status
                  } border-${data.repeat && data.borderStatus}`}
                >
                  <div className="display-b pr-15 pl-15 font-15 word-break color-dark font-700 text-center">
                    {data.title}
                  </div>
                  <span className="position-a check cursor">
                    {data.checked && (
                      <span className={`check__inside position-a bg-checked`}></span>
                    )}
                  </span>
                </div>
              </div>
            );
          } else {
            return (
              <div key={index} className="display-ib pl-10 pr-10">
                <Link to={`/page/hiddens/project/${data.classId}/${data._id}`}>
                  <div
                    className={`card project_inner_card justify-center padding-0 ${
                      data.status
                    } border-${data.repeat && data.borderStatus}`}
                  >
                    <div className="display-b pr-15 pl-15 font-15 word-break color-dark font-700 text-center">
                      {data.title}
                    </div>
                  </div>
                </Link>
              </div>
            );
          }
        })}
      </div>
    </div>
  );
};

export default Hidden;
