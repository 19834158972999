import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { postPasswordChange } from "../common/Action";
import { t } from "i18next";

const ChangePw: React.FC = () => {
  const params: any = useParams();
  // let location = useLocation();
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  useEffect(() => {
    localStorage.setItem("token", params.token);
    return () => {};
  }, []);

  const handleSubmit = async () => {
    if (password !== passwordConfirm) {
      // return alert("같은 비밀번호를 입력해주세요.");
      return alert(t(`Please enter the same password`));
    }
    const result = await postPasswordChange(password);
    if (!result) {
      // alert("잘못된 요청입니다. 메일을 확인해주세요.");
      alert(t(`Invalid request please check your email`));
    } else {
      localStorage.removeItem("token");
      // alert("비밀번호가 변경되었습니다. 변경된 비밀번호로 로그인해주세요.");
      alert(t(`Your password has been changed Sign in with your changed password`));
      window.location.href = "/";
    }
  };

  return (
    <>
      <div className="container text-center pt-41">
        <div className="sign-up-bottom text-left">
          <p className="margin-0 sign__up__text font-700 font-30">
            {/* 비밀번호 변경 */}
            {t(`Change your password`)}
          </p>

          <p className="margin-0 font-500 font-15 mb-12">
            {/* 비밀번호 */}
            {t(`Password`)}
          </p>
          <input
            onChange={(e) => setPassword(e.target.value)}
            type="password"
            value={password}
            className="mb-12 font-18 pl-36 pw-input"
            // placeholder="비밀번호를 입력해주세요."
            placeholder={t(`Please enter your password`)}
          />
          <p className="margin-0 font-500 font-15 mb-12">
            {/* 비밀번호 확인 */}
            {t(`Confirm password`)}
          </p>
          <input
            onChange={(e) => setPasswordConfirm(e.target.value)}
            type="password"
            value={passwordConfirm}
            className="mb-12 font-18 pl-36 pw-input"
            // placeholder="비밀번호를 다시 입력해주세요."
            placeholder={t(`Please enter your password again`)}
          />
          <br />
          <br />
          <button
            onClick={handleSubmit}
            className="mb-26 line-54 font-16 font-500 check-button mr-40"
          >
            {/* 비밀번호 변경 */}
            {t(`Change your password`)}
          </button>
          <Link to="/login">
            <button
              onClick={() => localStorage.removeItem("token")}
              className="mb-26 white line-54 font-16 font-500 cancel-button"
            >
              {/* 취소 */}
              {t(`Cancel`)}
            </button>
          </Link>
        </div>
      </div>
    </>
  );
};

export default ChangePw;
