import React, { useEffect, useRef } from "react";
import cookie1S from "../images/cookie1S.png";
import cookie2S from "../images/cookie2S.png";
import cookie3S from "../images/cookie3S.png";
import cookie1G from "../images/cookie1G.png";
import cookie2G from "../images/cookie2G.png";
import cookie3G from "../images/cookie3G.png";
import { t } from "i18next";

const HiddenProjectView = (props: any) => {
  const popupRef = useRef<any>(null);

  useEffect(() => {
    document.body.style.cssText = `
      position: fixed; 
      top: -${window.scrollY}px;
      overflow-y: scroll;
      width: 100%;`;
    return () => {
      const scrollY = document.body.style.top;
      document.body.style.cssText = "";
      window.scrollTo(0, parseInt(scrollY || "0", 10) * -1);
    };
  }, []);

  return (
    <div className="popup">
      <div className="modal-overlay" onClick={props.handleClickOutside}></div>
      <div ref={popupRef} className="projectOfStudentPopup scroll color-light-black">
        <p className="font-20 color-light-black mb-10 text-center">
          {/* {props.projectOfStudentData?.projectInfo[0]?.title} */}
        </p>
        <div className="display-f align-center flex-wrap">
          {props.projectOfStudentData?.fileUrls?.map((data: any, index: number) => (
            <div
              key={index}
              className="display-f download_item mb-10 align-center"
              style={{
                width: "49%",
                marginRight: index % 2 === 1 ? 0 : 5,
                marginLeft: index % 2 === 0 ? 0 : 5,
                flexDirection: "column",
              }}
            >
              <div>
                {data.isVideo ? (
                  <>
                    <video
                      controls
                      onClick={() => props.handleAlbum(data)}
                      src={data.uri}
                      className="background-img student_submitted_image mb-10 cursor"
                      autoPlay={true}
                    ></video>
                  </>
                ) : (
                  <div
                    onClick={() => props.handleAlbum(data)}
                    // style={{ backgroundImage: `url(${data.uri})` }}
                    className="mb-10 cursor"
                  >
                    <img src={data.uri} alt="studentImg" className="img-response" />
                  </div>
                )}
              </div>

              <p onClick={() => window.open(data.uri)} className="download_text cursor">
                {/* 다운받기 */}
                {t(`Download`)}
              </p>
            </div>
          ))}
        </div>

        {/* {studentBodyFalse && ( */}
        <div className="project_list mb-26" style={{ borderRadius: 10 }}>
          <p>
            {props.projectOfStudentData?.studentBody && (
              <div
                className="cursor"
                dangerouslySetInnerHTML={{ __html: props.projectOfStudentData.studentBody }}
              ></div>
            )}
          </p>
        </div>
        {/* )} */}

        {props.confirmType !== "reject" && (
          <div className="display-f  align-center mb-20">
            <p className="font-15 font-500 mr-33">
              {/* 피드백 */}
              {t(`Feedback`)}
            </p>
            <div className="feedback__text">{props.feedback}</div>
          </div>
        )}
        <div className="display-f justify-between align-center mb-28">
          <button
            className={`focus project__sign__btn light-black ${
              props.confirmType === "cookie" && "confirm_selected"
            }`}
          >
            {/* 쿠키 */}
            {t(`Cookie`)}
          </button>
          <button
            className={`focus project__sign__btn light-black ${
              props.confirmType === "score" && "confirm_selected"
            }`}
          >
            {/* 성적 */}
            {t(`Grades`)}
          </button>
          <button
            className={`focus project__sign__btn light-black project_reject_btn ${
              props.confirmType === "reject" && "confirm_reject_selected"
            }`}
          >
            {/* 반려 */}
            {t(`Reject`)}
          </button>
        </div>
        <div className="mb-40">
          {props.confirmType === "cookie" && (
            <div className="display-f align-center">
              <button
                className={`${
                  props.projectOfStudentData.cookie !== 1 && "cookie_border_none"
                } cookie_circle cursor display-f-center mr-17`}
              >
                {props.projectOfStudentData.cookie === 1 ? (
                  <img className="cookie1" src={cookie1S} alt="cookie1S" />
                ) : (
                  <img className="cookie1" src={cookie1G} alt="cookie1G" />
                )}
              </button>
              <button
                className={`${
                  props.projectOfStudentData.cookie !== 2 && "cookie_border_none"
                } cookie_circle cursor display-f-center mr-17`}
              >
                {props.projectOfStudentData.cookie === 2 ? (
                  <img className="cookie2" src={cookie2S} alt="cookie2S" />
                ) : (
                  <img className="cookie2" src={cookie2G} alt="cookie2G" />
                )}
              </button>
              <button
                className={`${
                  props.projectOfStudentData.cookie !== 3 && "cookie_border_none"
                } cookie_circle cursor display-f-center`}
              >
                {props.projectOfStudentData.cookie === 3 ? (
                  <img className="cookie3" src={cookie3S} alt="cookie3S" />
                ) : (
                  <img className="cookie3" src={cookie3G} alt="cookie3G" />
                )}
              </button>
            </div>
          )}
          {props.confirmType === "score" && (
            <div className="display-f align-center">
              <p className="mr-23 font-500 font-15">
                {/* 성적 입력 */}
                {t(`Enter grades`)}
              </p>
              <input value={props.score} maxLength={4} className="score_input" type="text" />
            </div>
          )}
          {props.confirmType === "reject" && (
            <>
              <div className="display-f align-center justify-between">
                <p className="font-500 font-15">반려 사유</p>
                <div className="reject__reason__text" style={{ backgroundColor: "#fff" }}>
                  {props.rejectReason}
                </div>
              </div>
            </>
          )}
        </div>
        {/* <div className="display-f-center">
          <button onClick={handleConfirmProject} className="popup__bottom__btn mr-14 ">
            확인
          </button>
          <button onClick={handleCancelConfirmPopup} className="popup__bottom__btn">
            취소
          </button>
        </div> */}
      </div>
    </div>
  );
};

export default HiddenProjectView;
