import React, { useState } from "react";
import { getChecklist, putCancelCheckCos, putCheckCos, putModifyCos } from "../common/Action";
import {
  getinitialChecklistReduced,
  initialChecklist,
  initialChecklistReduced,
} from "../common/Method";
import check from "../images/check2.png";
import hidden from "../images/hide.png";
import { t } from "i18next";

export const TableHead = (props: any) => {
  return (
    <section>
      <div className="t_head_wrapper" style={{ ...props.headerStyle }}>
        <div
          className="t_head_cell3 gray font-500"
          style={{
            position: "sticky",
            left: 0,
            height: 40,
            borderRight: "1px solid #979797",
          }}
        >
          {/* 학생 */}
          {t(`Student`)}
        </div>
        {props.headData?.map((aChecklist: any, i: number) => (
          <div
            className="t_head_cell gray font-500"
            style={{
              borderLeft: i === 0 ? "none" : "1px solid #979797",
              borderRight: i === props.headData.length - 1 ? "1px solid #979797" : "",
              height: 40,
            }}
          >
            <div className="area white-space overflow ellipsis text-center">
              {aChecklist.hide && (
                <div className="mr-5">
                  <img src={hidden} style={{ width: 14, height: "auto", marginBottom: 2 }} />
                </div>
              )}
              {aChecklist.title}
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export const TableBody = (props: any) => {
  const [popup, setPopup] = useState<boolean>(false);
  const [checklistInfo, setChecklistInfo] = useState<any>({});
  const [cosData, setCosData] = useState<any>({});
  const [addCosData, setAddCosData] = useState<any>({
    description: "",
    state: "",
  });
  const [save, setSave] = useState<boolean>(false);
  const [hidden, setHidden] = useState<boolean>(false);

  const onOpenPopup = async (aCos: any, el: number) => {
    if (aCos.checklistHide) setHidden(true);
    else setHidden(false);

    if (aCos.cos.state === "" && aCos.checklistHide) {
      // return alert(`숨긴 체크리스트입니다.\n기록하시려면 숨김을 해제해 주세요.`);
      return alert(t(`This is a hidden checklist Please unhide...`));
    }
    if (aCos.cos.state !== "") {
      setPopup(true);
      setCosData(aCos);
      setAddCosData((prev: any) => {
        return {
          ...prev,
          description: aCos.cos.description,
          state: aCos.cos.state,
        };
      });
      const checkDataResult: any = await getChecklist(aCos.checklsitId);
      if (checkDataResult) setChecklistInfo(checkDataResult);
    }
    if (aCos.cos.state === "" && !aCos.checklistHide) {
      // 체크 안되어 있는 경우 간편체크
      let state = "complete";
      await putCheckCos(aCos.cos.cosId, state);
      const result = await getinitialChecklistReduced(props.headData, props.classId);
      props.setStudentsData(result.tempChecklistRowData);
    }
  };

  const handleSave = async (): Promise<void> => {
    // 상세기록 저장
    const update: any = await putModifyCos({
      id: cosData.cos.cosId,
      description: addCosData.description,
    });
    if (update) setSave(true);
    setTimeout(() => {
      setSave(false);
    }, 500);
    const result = await getinitialChecklistReduced(props.headData, props.classId);
    props.setStudentsData(result.tempChecklistRowData);
  };

  const handleSubmitChecklist = async (): Promise<void> => {
    let state = addCosData.state === "" ? "complete" : addCosData.state;
    await putCheckCos(cosData.cos.cosId, state);
    await handleSave();
    setPopup(false);

    const result = await getinitialChecklistReduced(props.headData, props.classId);
    props.setStudentsData(result.tempChecklistRowData);
  };

  const handleUnSubmit = async (): Promise<void> => {
    await putCancelCheckCos(cosData.cos.cosId);

    setAddCosData({
      description: "",
      state: "",
    });

    setPopup(false);
    const result = await getinitialChecklistReduced(props.headData, props.classId);
    props.setStudentsData(result.tempChecklistRowData);
  };

  return (
    <div>
      {popup && (
        <div className="popup" style={{ zIndex: 100 }}>
          <div className="modal-overlay" onClick={() => setPopup(false)}></div>
          <div
            className="project_add_popup scroll popup_column_sb"
            style={{ backgroundColor: "rgba(246, 248, 250)" }}
          >
            <div>
              {/* <p>{JSON.stringify(clickedStudentInfo)}</p> */}
              <h2 className="text-center">
                {/* {JSON.stringify(props.headData)}  */}
                {cosData.cos.name} {checklistInfo.title}
              </h2>
              <div
                style={{
                  paddingTop: 20,
                  padding: "20px 0 20px 10px",
                  backgroundColor: "#fff",
                }}
              >
                <div dangerouslySetInnerHTML={{ __html: checklistInfo.description }} />

                <div className="display-f" style={{ flexWrap: "wrap" }}>
                  {checklistInfo?.file?.map((url: string, i: number) => (
                    <img key={i} src={url} style={{ width: "48%" }} />
                  ))}
                </div>
              </div>
              <div>
                <div className="display-f align-center">
                  <h3>
                    {/* 상세 기록 */}
                    {t(`Detailed history`)}
                  </h3>
                  {!hidden && (
                    <button onClick={handleSave} className="checklist-save-btn ml-15">
                      {/* 기록 저장 */}
                      {t(`Save records`)}
                    </button>
                  )}

                  {save && !hidden && (
                    <p className="ml-7" style={{ color: "red", fontSize: 10 }}>
                      {/* 기록이 저장되었습니다. */}
                      {t(`Your records have been saved`)}
                    </p>
                  )}

                  {!save && !hidden && (
                    <p className="ml-7" style={{ color: "#979797", fontSize: 10 }}>
                      {/* 기록 저장을 누르지 않으면, 상세 기록이 저장되지 않습니다. */}
                      {t(`If you don't tap Save history...`)}
                    </p>
                  )}
                </div>
                <textarea
                  readOnly={hidden ? true : false}
                  onChange={(e: any) => {
                    setAddCosData((prev: any) => {
                      return {
                        ...prev,
                        description: e.target.value,
                      };
                    });
                  }}
                  value={addCosData.description}
                  rows={16}
                  className="popup-checklist-textarea"
                />
              </div>
            </div>

            <div>
              <div className="pt-20 pb-20 display-f align-center">
                <p className="mr-20">
                  {/* 간단 메모 */}
                  {t(`Brief memo`)}
                </p>
                <input
                  readOnly={hidden ? true : false}
                  value={addCosData.state === "complete" ? "" : addCosData.state}
                  className="popup-scrore-input"
                  onChange={(e) => {
                    if (e.target.value.length > 10) return;
                    setAddCosData((prev: any) => {
                      return {
                        ...prev,
                        state: e.target.value,
                      };
                    });
                  }}
                />
              </div>

              {!hidden && (
                <div className="display-f justify-center mt-20 mb-10">
                  <button
                    onClick={handleSubmitChecklist}
                    className="project__sign__btn background-red-btn mr-10 color-white"
                  >
                    {/* 체크 */}
                    {t(`Check`)}
                  </button>
                  <button
                    onClick={handleUnSubmit}
                    className="project__sign__btn background-green-btn color-white"
                  >
                    {/* 체크 취소 */}
                    {t(`Cancel Check`)}
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      )}

      <section className="table_body_container">
        {Object.keys(props.studentsData).map((el: any, i: number) => (
          <div className="text-center t_head_wrapper_body">
            <div
              className="w-100 t_head_cell white-space overflow ellipsis text-center"
              style={{
                position: "sticky",
                left: 0,
                flexBasis: 65,
                borderBottom:
                  Object.keys(props.studentsData).length - 1 === i ? "1px solid #979797" : "",
              }}
            >
              {props.studentsData[el].num}
            </div>
            <div
              className="w-100 t_head_cell overflow"
              style={{
                position: "sticky",
                left: 65,
                flexBasis: 85,
                borderRight: "1px solid #979797",
                borderBottom:
                  Object.keys(props.studentsData).length - 1 === i ? "1px solid #979797" : "",
              }}
            >
              <p className="area white-space overflow ellipsis text-center">
                {props.studentsData[el].name}
              </p>
            </div>

            {props.studentsData[el].cos.map((aCos: any, index: number) => (
              <>
                {/* 미확인 */}
                {!aCos.cos.hide && aCos.cos.state === "" && (
                  <div
                    onClick={() => onOpenPopup(aCos, el)}
                    className="green w-200 t_head_cell cursor"
                    style={{
                      borderLeft: index === 0 ? "none" : "1px solid #979797",
                      borderRight:
                        props.studentsData[el].cos.length - 1 === index ? "1px solid #979797" : "",
                      borderBottom:
                        Object.keys(props.studentsData).length - 1 === i ? "1px solid #979797" : "",
                    }}
                  ></div>
                )}
                {/* 확인 완료 */}
                {!aCos.cos.hide &&
                  aCos.cos.state !== "" &&
                  aCos.cos.state !== "complete" &&
                  aCos.cos.state && (
                    <div
                      onClick={() => onOpenPopup(aCos, el)}
                      className="card-text-red font-500 gray w-200 t_head_cell cursor"
                      style={{
                        borderLeft: index === 0 ? "none" : "1px solid #979797",
                        borderRight:
                          props.studentsData[el].cos.length - 1 === index
                            ? "1px solid #979797"
                            : "",
                        borderBottom:
                          Object.keys(props.studentsData).length - 1 === i
                            ? "1px solid #979797"
                            : "",
                      }}
                    >
                      {aCos.cos.state}
                    </div>
                  )}
                {/* easy check */}
                {!aCos.cos.hide && aCos.cos.state === "complete" && aCos.cos.state && (
                  <div
                    onClick={() => onOpenPopup(aCos, el)}
                    className="gray w-200 t_head_cell cursor"
                    style={{
                      borderLeft: index === 0 ? "none" : "1px solid #979797",
                      borderRight:
                        props.studentsData[el].cos.length - 1 === index ? "1px solid #979797" : "",
                      borderBottom:
                        Object.keys(props.studentsData).length - 1 === i ? "1px solid #979797" : "",
                    }}
                  >
                    <img src={check} style={{ width: 20, height: "auto" }} />
                  </div>
                )}
                {/* 학생 미등록 */}
                {(aCos.cos.hide || Object.keys(aCos.cos).length === 0) && (
                  <div
                    className="w-200 t_head_cell"
                    style={{
                      borderLeft: index === 0 ? "none" : "1px solid #979797",
                      borderRight:
                        props.studentsData[el].cos.length - 1 === index ? "1px solid #979797" : "",
                      borderBottom:
                        Object.keys(props.studentsData).length - 1 === i ? "1px solid #979797" : "",
                    }}
                  ></div>
                )}
              </>
            ))}
          </div>
        ))}
      </section>
    </div>
  );
};
