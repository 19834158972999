import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import logoTop from "../images/logo-top.png";
import logoBottom from "../images/logo-bottom.png";
import { getExistEmail, getExistId, getUserVerify, postSignup } from "../common/Action";
import { t } from "i18next";

const SignUp: React.FC = () => {
  const history = useHistory();
  const [name, setName] = useState<string>("");
  const [id, setId] = useState<string>("");
  const [pw, setPw] = useState<string>("");
  const [pwCheck, setPwCheck] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [phone, setPhone] = useState<string>("");
  const [modal, setModal] = useState<boolean>(false);
  const [agree1, setAgree1] = useState<boolean>(false);
  const [agree2, setAgree2] = useState<boolean>(false);
  const [isIdValid, setIsIdValid] = useState<boolean>(false);
  const [isEmailValid, setIsEmailValid] = useState<boolean>(false);
  // const [idConfirmed, setIdConfirmed] = useState<boolean>(false);
  // const [emailConfirmed, setEmailConfirmed] = useState<boolean>(false);

  // useEffect((): void => {
  //   setIdConfirmed(false);
  //   setEmailConfirmed(false);
  // }, [id]);

  const handleCheckSame = async (): Promise<void> => {
    if (id === "") return;
    const result = await getUserVerify("username", id);
    if (!result) {
      setIsIdValid(true);
      alert(
        // "사용 가능한 아이디입니다!"
        t(`This is an available ID`),
      );
      // setIdConfirmed(true);
    } else {
      alert(
        // "사용이 불가능한 아이디입니다. 관리자에게 문의해주세요."
        t(`The ID is unavailable...`),
      );
    }
  };

  const handleCheckEmail = async (): Promise<void> => {
    if (email === "") return;
    if (!verifyEmail()) {
      return alert(
        // "이메일 형식을 확인해주세요."
        t(`Please check your email format`),
      );
    }
    const result = await getUserVerify("email", email);
    if (!result) {
      setIsEmailValid(true);
      alert(
        // "사용 가능한 이메일입니다!"
        t(`Your email is available`),
      );
      // setEmailConfirmed(true);
    } else {
      alert(
        // "사용이 불가능한 이메일입니다. 관리자에게 문의해주세요."
        t(`This email is not available...`),
      );
    }
  };

  const handleEmail = (e: any) => {
    e.preventDefault();

    setEmail(e.target.value);
  };

  const handleSubmit = async () => {
    if (!isIdValid) {
      return alert(
        // "아이디 중복확인을 진행해주세요."
        t(`Please proceed with ID duplication check`),
      );
    }
    if (!isEmailValid) {
      return alert(
        // "이메일 중복확인을 진행해주세요."
        t(`Please proceed with the email duplicate check`),
      );
    }
    if (!agree1) {
      return alert(
        // "회원가입 약관에 동의해주세요."
        t(`I agree to the Terms of Service`),
      );
    }
    if (!agree2) {
      return alert(
        // 개인정보 수집 약관에 동의해주세요."
        t(`Please agree to the Privacy Policy`),
      );
    }
    if (name === "") {
      return alert(
        // "이름을 입력해주세요."
        t(`Please enter a name`),
      );
    }
    if (id === "") {
      return alert(
        // "아이디를 입력해주세요."
        t(`Please enter ID`),
      );
    }

    // if (!idConfirmed) return alert("아이디를 중복확인 해주세요.");
    if (email === "") {
      return alert(
        // "이메일을 입력해주세요."
        t(`Please enter your email`),
      );
    }
    if (!verifyEmail()) {
      return alert(
        // "이메일 형식을 확인해주세요."
        t(`Please check the email format`),
      );
    }
    // if (!emailConfirmed) return alert("이메일을 중복확인 해주세요.");
    if (pw !== pwCheck) {
      return alert(
        // "같은 비밀번호를 입력해주세요."
        t(`Please enter the same password`),
      );
    }
    // if (phone === "") return alert("전화번호를 입력해주세요.");

    const result = await postSignup({
      name: name,
      id: id.trim(),
      email: email.trim(),
      // phone: phone,
      password: pw.trim(),
    });

    if (!result) {
      alert();
      // "가입이 불가능합니다. 아이디 혹은 이메일을 확인해 주세요!"
      t(`Unable to sign up please verify your ID or email`);
    } else if (result === "existid") {
      alert(
        // "이미 가입된 아이디입니다. 아이디와 비밀번호 찾기를 해 주세요!"
        t(`You're already registered Please check your ID...`),
      );
    } else if (result === "existemail") {
      alert(
        // "이미 가입된 이메일입니다. 이메일을 확인해 주세요!"
        t(`Your email is valid Please verify your email`),
      );
    } else {
      alert(
        // "회원가입이 완료되었습니다. 가입된 아이디로 로그인 해 주세요."
        t(`Your registration is complete please sign in...`),
      );
      history.goBack();
    }
    // history.goBack();
  };

  const verifyEmail = () => {
    if (email === "") return true;
    const str =
      /^([\w\.\_\-])*[a-zA-Z0-9]+([\w\.\_\-])*([a-zA-Z0-9])+([\w\.\_\-])+@([a-zA-Z0-9]+\.)+[a-zA-Z0-9]{2,8}$/;
    return str.test(email);
  };

  const Modal: React.FC = () => {
    return (
      <div className="modal">
        <div className="modal-in">
          <h2>약관동의</h2>
          <hr />
          <h3>회원가입 약관에 모두 동의합니다.</h3>

          <h4>이용약관 동의 (필수)</h4>
          <div className="box">
            <p>제 1 장 총 칙</p>
            <p>&nbsp;</p>
            <p>
              제 1 조 (목적)
              <br />이 약관은 다했니?다했어요!(이하 "사이트"라 합니다)에서 제공하는
              인터넷서비스(이하 "서비스"라 합니다)의 이용 조건 및 절차에 관한 기본적인 사항을
              규정함을 목적으로 합니다.
            </p>
            <p>&nbsp;</p>
            <p>
              제 2 조 (약관의 효력 및 변경)
              <br />
              ①&nbsp;이 약관은 서비스 화면이나 기타의 방법으로 이용고객에게 공지함으로써 효력을
              발생합니다.
              <br />
              ②&nbsp;사이트는 이 약관의 내용을 변경할 수 있으며, 변경된 약관은 제1항과 같은 방법으로
              공지 또는 통지함으로써 효력을 발생합니다.
            </p>
            <p>&nbsp;</p>
            <p>
              제 3 조 (용어의 정의)
              <br />이 약관에서 사용하는 용어의 정의는 다음과 같습니다.
              <br />
              ①&nbsp;회원 : 사이트와 서비스 이용계약을 체결하거나 이용자 아이디(ID)를 부여받은 개인
              또는 단체를 말합니다.
              <br />
              ②&nbsp;신청자 : 회원가입을 신청하는 개인 또는 단체를 말합니다.
              <br />
              ③&nbsp;아이디(ID) : 회원의 식별과 서비스 이용을 위하여 회원이 정하고 사이트가 승인하는
              문자와 숫자의 조합을 말합니다.
              <br />
              ④&nbsp;비밀번호 : 회원이 부여 받은 아이디(ID)와 일치된 회원임을 확인하고, 회원 자신의
              비밀을 보호하기 위하여 회원이 정한 문자와 숫자의 조합을 말합니다.
              <br />
              ⑤&nbsp;해지 : 사이트 또는 회원이 서비스 이용계약을 취소하는 것을 말합니다.
            </p>
            <p>&nbsp;</p>
            <p>제 2 장 서비스 이용계약</p>
            <p>&nbsp;</p>
            <p>
              제 4 조 (이용계약의 성립)
              <br />
              ①&nbsp;이용약관 하단의 동의 버튼을 누르면 이 약관에 동의하는 것으로 간주됩니다.
              <br />
              ②&nbsp;이용계약은 서비스 이용희망자의 이용약관 동의 후 이용 신청에 대하여 사이트가
              승낙함으로써 성립합니다.
            </p>
            <p>&nbsp;</p>
            <p>
              제 5 조 (이용신청)
              <br />
              ①&nbsp;신청자가 본 서비스를 이용하기 위해서는 사이트 소정의 가입신청 양식에서 요구하는
              이용자 정보를 기록하여 제출해야 합니다.
              <br />
              ②&nbsp;가입신청 양식에 기재하는 모든 이용자 정보는 모두 실제 데이터인 것으로
              간주됩니다. 실명이나 실제 정보를 입력하지 않은 사용자는 법적인 보호를 받을 수 없으며,
              서비스의 제한을 받을 수 있습니다.
            </p>
            <p>&nbsp;</p>
            <p>
              제 6 조 (이용신청의 승낙)
              <br />
              ①&nbsp;사이트는 신청자에 대하여 제2항, 제3항의 경우를 예외로 하여 서비스 이용신청을
              승낙합니다.
              <br />
              ②&nbsp;사이트는 다음에 해당하는 경우에 그 신청에 대한 승낙 제한사유가 해소될 때까지
              승낙을 유보할 수 있습니다.
              <br />
              가. 서비스 관련 설비에 여유가 없는 경우
              <br />
              나. 기술상 지장이 있는 경우
              <br />
              다. 기타 사이트가 필요하다고 인정되는 경우
              <br />
              ③&nbsp;사이트는 신청자가 다음에 해당하는 경우에는 승낙을 거부할 수 있습니다.
              <br />
              가. 다른 개인(사이트)의 명의를 사용하여 신청한 경우
              <br />
              나. 이용자 정보를 허위로 기재하여 신청한 경우
              <br />
              다. 사회의 안녕질서 또는 미풍양속을 저해할 목적으로 신청한 경우
              <br />
              라. 기타 사이트 소정의 이용신청요건을 충족하지 못하는 경우
            </p>
            <p>&nbsp;</p>
            <p>
              제 7 조 (이용자정보의 변경)
              <br />
              회원은 이용 신청시에 기재했던 회원정보가 변경되었을 경우에는, 온라인으로 수정하여야
              하며 변경하지 않음으로 인하여 발생되는 모든 문제의 책임은 회원에게 있습니다.
            </p>
            <p>&nbsp;</p>
            <p>제 3 장 계약 당사자의 의무</p>
            <p>&nbsp;</p>
            <p>
              제 8 조 (사이트의 의무)
              <br />
              ①&nbsp;사이트는 회원에게 각 호의 서비스를 제공합니다.
              <br />
              가. 신규서비스와 도메인 정보에 대한 뉴스레터 발송
              <br />
              나. 추가 도메인 등록시 개인정보 자동 입력
              <br />
              다. 도메인 등록, 관리를 위한 각종 부가서비스
              <br />
              ②&nbsp;사이트는 서비스 제공과 관련하여 취득한 회원의 개인정보를 회원의 동의없이
              타인에게 누설, 공개 또는 배포할 수 없으며, 서비스관련 업무 이외의 상업적 목적으로
              사용할 수 없습니다. 단, 다음 각 호의 1에 해당하는 경우는 예외입니다.
              <br />
              가. 전기통신기본법 등 법률의 규정에 의해 국가기관의 요구가 있는 경우
              <br />
              나. 범죄에 대한 수사상의 목적이 있거나 정보통신윤리 위원회의 요청이 있는 경우
              <br />
              다. 기타 관계법령에서 정한 절차에 따른 요청이 있는 경우
              <br />
              ③&nbsp;사이트는 이 약관에서 정한 바에 따라 지속적, 안정적으로 서비스를 제공할 의무가
              있습니다.
            </p>
            <p>&nbsp;</p>
            <p>
              제 9 조 (회원의 의무)
              <br />
              ①&nbsp;회원은 서비스 이용 시 다음 각 호의 행위를 하지 않아야 합니다.
              <br />
              가. 다른 회원의 ID를 부정하게 사용하는 행위
              <br />
              나. 서비스에서 얻은 정보를 사이트의 사전승낙 없이 회원의 이용 이외의 목적으로
              복제하거나 이를 변경, 출판 및 방송 등에 사용하거나 타인에게 제공하는 행위
              <br />
              다. 사이트의 저작권, 타인의 저작권 등 기타 권리를 침해하는 행위
              <br />
              라. 공공질서 및 미풍양속에 위반되는 내용의 정보, 문장, 도형 등을 타인에게 유포하는
              행위
              <br />
              마. 범죄와 결부된다고 객관적으로 판단되는 행위
              <br />
              바. 기타 관계법령에 위배되는 행위
              <br />
              ②&nbsp;회원은 관계법령, 이 약관에서 규정하는 사항, 서비스 이용 안내 및 주의 사항을
              준수하여야 합니다.
              <br />
              ③&nbsp;회원은 내용별로 사이트가 서비스 공지사항에 게시하거나 별도로 공지한 이용 제한
              사항을 준수하여야 합니다.
            </p>
            <p>&nbsp;</p>
            <p>제 4 장 서비스 제공 및 이용</p>
            <p>&nbsp;</p>
            <p>
              제 10 조 (회원 아이디(ID)와 비밀번호 관리에 대한 회원의 의무)
              <br />
              ①&nbsp;아이디(ID)와 비밀번호에 대한 모든 관리는 회원에게 책임이 있습니다. 회원에게
              부여된 아이디(ID)와 비밀번호의 관리소홀, 부정사용에 의하여 발생하는 모든 결과에 대한
              전적인 책임은 회원에게 있습니다.
              <br />
              ②&nbsp;자신의 아이디(ID)가 부정하게 사용된 경우 또는 기타 보안 위반에 대하여, 회원은
              반드시 사이트에 그 사실을 통보해야 합니다.
            </p>
            <p>&nbsp;</p>
            <p>
              제 11 조 (서비스 제한 및 정지)
              <br />
              ①&nbsp;사이트는 전시, 사변, 천재지변 또는 이에 준하는 국가비상사태가 발생하거나 발생할
              우려가 있는 경우와 전기통신사업법에 의한 기간통신 사업자가 전기통신서비스를 중지하는
              등 기타 불가항력적 사유가 있는 경우에는 서비스의 전부 또는 일부를 제한하거나 정지할 수
              있습니다.
              <br />
              ②&nbsp;사이트는 제1항의 규정에 의하여 서비스의 이용을 제한하거나 정지할 때에는 그 사유
              및 제한기간 등을 지체없이 회원에게 알려야 합니다.
            </p>
            <p>&nbsp;</p>
            <p>제5장 계약사항의 변경, 해지</p>
            <p>&nbsp;</p>
            <p>
              제 12 조 (정보의 변경)
              <br />
              회원이 주소, 비밀번호 등 고객정보를 변경하고자 하는 경우에는 홈페이지의 회원정보 변경
              서비스를 이용하여 변경할 수 있습니다.
            </p>
            <p>&nbsp;</p>
            <p>
              제 13 조 (계약사항의 해지)
              <br />
              회원은 서비스 이용계약을 해지할 수 있으며, 해지할 경우에는 본인이 직접 서비스를
              통하거나 전화 또는 온라인 등으로 사이트에 해지신청을 하여야 합니다. 사이트는
              해지신청이 접수된 당일부터 해당 회원의 서비스 이용을 제한합니다. 사이트는 회원이 다음
              각 항의 1에 해당하여 이용계약을 해지하고자 할 경우에는 해지조치 7일전까지 그 뜻을
              이용고객에게 통지하여 소명할 기회를 주어야 합니다.
              <br />
              ①&nbsp;이용고객이 이용제한 규정을 위반하거나 그 이용제한 기간 내에 제한 사유를
              해소하지 않는 경우
              <br />
              ②&nbsp;정보통신윤리위원회가 이용해지를 요구한 경우
              <br />
              ③&nbsp;이용고객이 정당한 사유 없이 의견진술에 응하지 아니한 경우
              <br />
              ④&nbsp;타인 명의로 신청을 하였거나 신청서 내용의 허위 기재 또는 허위서류를 첨부하여
              이용계약을 체결한 경우
              <br />
              사이트는 상기 규정에 의하여 해지된 이용고객에 대해서는 별도로 정한 기간동안 가입을
              제한할 수 있습니다.
            </p>
            <p>&nbsp;</p>
            <p>제6장 손해배상</p>
            <p>&nbsp;</p>
            <p>
              제 14 조 (면책조항)
              <br />
              ①&nbsp;사이트는 회원이 서비스 제공으로부터 기대되는 이익을 얻지 못하였거나 서비스
              자료에 대한 취사선택 또는 이용으로 발생하는 손해 등에 대해서는 책임이 면제됩니다.
              <br />
              ②&nbsp;사이트는 회원의 귀책사유나 제3자의 고의로 인하여 서비스에 장애가 발생하거나
              회원의 데이터가 훼손된 경우에 책임이 면제됩니다.
              <br />
              ③&nbsp;사이트는 회원이 게시 또는 전송한 자료의 내용에 대해서는 책임이 면제됩니다.
              <br />
              ④&nbsp;상표권이 있는 도메인의 경우, 이로 인해 발생할 수도 있는 손해나 배상에 대한
              책임은 구매한 회원 당사자에게 있으며, 사이트는 이에 대한 일체의 책임을 지지 않습니다.
            </p>
            <p>&nbsp;</p>
            <p>제 15 조 (관할법원)</p>
            <p>&nbsp;</p>
            <p>
              서비스와 관련하여 사이트와 회원간에 분쟁이 발생할 경우 사이트의 본사 소재지를 관할하는
              법원을 관할법원으로 합니다.
            </p>
            <p>&nbsp;</p>
            <p>[부칙]</p>
            <p>&nbsp;</p>
            <p>(시행일) 이 약관은 2021년 10월부터 시행합니다.</p>
          </div>

          <br />
          <h4>개인정보 수집 및 이용 동의 (필수)</h4>
          <div className="box">
            <p>
              <strong className="font-bold">개인 정보 보호 정책</strong>
            </p>
            <br />
            <p>
              본 개인 정보 보호 정책은 에듀지원 주식회사("에듀지원", "회사", "당사", “본사”)에서
              제공하는 모든 제품, 서비스 및 콘텐츠에 적용됩니다.
            </p>
            <br />
            <p>
              <strong>개요</strong>
            </p>
            <p>
              당사는 사용자의 개인 정보 보호를 중요하게 생각합니다. 본 정책은 dahandin.com(“다했니”)
              및 dahandout.com(“다했어요”), 당사의 모바일 및 데스크톱 애플리케이션("앱"), 브라우저
              확장, 블로그 및 관련 제품 및 서비스(집합적으로 "서비스")에 대한 액세스 및 사용과
              관련하여 수집된 정보를 처리하는 방법을 설명합니다. 본 서비스에 액세스하거나 사용할 때,
              귀하는 본 정책에 설명된 대로 정보의 수집, 사용, 유지, 이전, 구조화, 조작, 저장, 전송
              및/또는 공개(집합적으로 "처리")에 동의한 것으로 간주됩니다.
            </p>
            <br />
            <p>
              이 정책은 사용자가 자신의 ‘학급’(즉, 서비스 사용자 만드는 사용자 정의 페이지)에서
              수행하는 작업을 관리하지 않습니다. 사용자가 만든 학급 페이지에서 개인 정보를 공유할 때
              신중을 기할 것을 요청합니다.
            </p>
            <br />
            <p>
              이 정책은 또한 서비스의 링크 또는 기타 기능(예: 유튜브 비디오, 트위터 버튼)을 통해
              액세스할 수 있는 웹 사이트 또는 서비스에는 적용되지 않습니다. 이러한 다른 서비스에는
              자체 개인 정보 정책이 있으므로 개인 정보를 제공하기 전에 검토하는 것이 좋습니다.
            </p>
            <br />
            <p>
              귀하가 본 서비스를 이용할 때 당사는 귀하에 대한 다음과 같은 개인 정보를 요청하고
              수집할 수 있습니다. 본 정보는 귀하와 당사 간의 계약의 적절한 이행, 본 서비스의 제공 및
              개선에 대한 당사의 정당한 이익, 당사의 법적 의무 준수를 위해 필요합니다. 이러한 정보가
              없다면 당사는 의도한 대로 본 서비스를 제공할 수 없을 수 있습니다. 당사는 아래에 나열된
              범주 외에 다른 범주의 개인 정보를 수집하지 않습니다. 당사는 본 정책에 명시된 목적을
              위해 해당 정보를 사용할 뿐입니다.
            </p>
            <br />
            <p>
              <strong>계정 및 프로필 정보</strong>
            </p>
            <p>
              서비스 계정을 만들 때 사용자 이름, 암호 및 이메일 주소와 같은 정보를 제공해야 합니다.
              이 정보는 사용자의 신원을 확인하고 서비스에 대한 액세스를 제공하는 데 사용됩니다. ​
              ‘학급명’, ‘학생명’, ‘학생 번호’,‘학생 연락처’ 등을 입력하여 학생들을 더 잘 식별할 수
              있습니다. 이는 선택 사항이며 서비스를 사용하는 데 필요하지 않습니다.
            </p>
            <br />
            <p>
              <strong>타사 서비스에 대한 계정에 대한 정보</strong>
            </p>
            <p>
              본사는 귀하의 카카오 계정을 사용하여 서비스에 로그인할 수 있는 기능을 제공합니다.
              이러한 서비스를 사용하여 본인을 인증하는 경우, 귀하의 이메일 주소 및 해당 서비스와
              관련된 귀하의 이름, 사진 및 사용자 이름(가능한 경우)에 대한 액세스 권한을 부여합니다.
              귀하의 비밀번호는 제공되지 않습니다.
            </p>
            <br />
            <p>
              <strong>서비스에 게시된 정보(사용자 콘텐츠)</strong>
            </p>
            <p>
              사용자는 자신과 다른 사용자(학생)가 상호 참여할 수 있는, 즉 콘텐츠를 게시할 수 있는
              ‘과제’ 나 ‘알림장’을 생성할 수 있습니다. 이 콘텐츠("사용자 콘텐츠")는 텍스트, 이미지,
              비디오, 오디오, 문서, 파일, 웹으로부터의 링크, 도면 및 지도 등을 포함할 수 있습니다. ​
              www.dahandin.com 이용자 (교사 및 관리자): 학급명, 학생명, 학생 번호, 학생 연락처 등의
              개인 정보를 선택하여 입력할 수 있습니다. 사용자 콘텐츠의 개인 정보는 개인의 사용
              방법에 따라 달라집니다. ​ www.dahandout.com 이용자: 발급된 사용자 코드로 기기에
              등록하여 해당 사용자만 콘텐츠를 볼 수 있습니다. 단, 개인 기기가 아닌 경우 반드시 ‘학급
              삭제하기’ 및 ‘코드 삭제’를 통해 다른 사용자가 접근할 수 없도록 스스로 보호해야합니다.
              ​ ​ 기기에 코드를 입력하는 경우 개인 정보 수준에 관계없이 주의를 기울이시기 바랍니다.
              ​ ​ 본 서비스를 다른 기기에서 사용하는 경우, 당사는 해당 기기에서 수집한 정보를 연결
              및 결합하여 귀하의 다른 기기에서 일관된 서비스를 제공할 수 있습니다. 당사가 자동으로
              수집한 정보를 개인 정보와 결합하는 경우, 당사는 결합된 정보를 개인 정보로 취급하며 본
              정책에 따라 보호됩니다.
            </p>
            <br />
            <p>
              <strong>쿠키를 사용하여 수집된 정보</strong>
            </p>
            <p>
              쿠키는 웹 사이트가 일정 기간 동안 사용자의 웹 브라우저에 저장할 수 있는 데이터입니다.
              모든 웹 사이트는 자체 쿠키 세트를 저장하고 설계에 따라 한 웹 사이트는 다른 웹 사이트의
              쿠키를 읽을 수 없습니다.
            </p>
            <p>다음과 같은 용도로 사용됩니다:</p>
            <br />
            <p>
              <strong>인증 사용자</strong>
            </p>
            <p>서비스를 사용하는 동안 사용자 로그인 유지</p>
            <p>사용자 환경설정 기억 (예: 시간대)</p>
            <p>
              외부 서비스(예: 유튜브)에 대한 링크를 공유하면 해당 서비스가 브라우저에도 쿠키를
              저장할 수 있습니다. 우리는 쿠키를 저장하고 쿠키로 무엇을 하는지에 대한 통제권이
              없습니다.
            </p>
            <br />
            <p>
              <strong>장치와 관련된 정보</strong>
            </p>
            <p>다음과 같은 장치별 정보를 수집합니다: ​ </p>
            <p>
              기기 브랜드, 버전, 종류(예: 삼성 갤럭시 S9 휴대폰) 운영 체제 및 버전(예: Android 8.0)
            </p>
            <p> 브라우저 유형 및 버전(예: Chrome 63.0)</p>{" "}
            <p>화면 크기 및 해상도(예: 375m 너비 망막 화면)</p>{" "}
            <p>
              이를 통해 서비스의 수행 방식을 측정하고, 특정 장치에서 사용자를 위한 패블릿을
              개선하고, 수신을 선택한 경우 푸시 알림을 보낼 수 있습니다. 계정 설정 페이지에서
              언제든지 알림을 선택할 수 있습니다.
            </p>
            <br />
            <p>
              <strong>서비스 이용과 관련된 정보</strong>
            </p>
            <p>
              당사는 사용자가 서비스를 사용하는 방법에 대한 정보를 수집합니다. 이 정보에는 일반적인
              사용 정보가 포함되며, 사용자가 방문한 페이지 또는 서비스의 기능, 클릭한 서비스의 링크
              등의 정보가 포함될 수 있습니다.
            </p>
            <br />
            <p>
              또한 당사는 이 정보 중 일부를 집계 형식으로 사용할 수도 있습니다. 즉, 귀하가 서비스에
              대한 경험을 향상시키고 향상시키기 위해 귀하를 개인적으로 식별하지 못할 당사의 모든
              사용자와 관련된 통계적 조치로 사용할 수도 있습니다. 당사는 어떠한 경우에도 이 데이터를
              판매하거나 다른 사용자와 공유하지 않습니다.
            </p>
            <br />
            <p>
              <strong>사용자 위치와 관련된 정보</strong>
            </p>
            <p>
              IP 주소는 인터넷에 연결된 모든 장치에 할당된 주소입니다. 사용자의 위치에 따라 IP
              주소는 컴퓨터에 고유할 수도 있고 여러 장치 간에 공유될 수도 있습니다. IP 주소는 할당된
              장치를 찾는 데 사용할 수도 있고 사용자가 해당 장치를 사용할 수도 있습니다.
            </p>
            <br />
            <p>
              <strong>서비스를 통해 다른 사용자와 공유하는 정보</strong>
            </p>
            <p>
              다했니 사용자와 다했어요 사용자는 서로 공유한 사진, 동영상, 링크, 파일, 텍스트 등을 볼
              수 있습니다.
            </p>
            <p>
              <strong>서비스 운영 및 개선을 위해 서비스 공급자와 공유하는 정보</strong>
            </p>
            <p>
              당사는 타사 공급업체, 서비스 공급업체 및 기타 파트너와 협력하여 당사를 대신하여 작업을
              수행하여 서비스를 제공할 수 있도록 지원합니다.
            </p>
            <br />
            <p>
              이러한 제공자는 이러한 작업을 수행하기 위해 귀하의 개인 정보에 대한 액세스가 제한되며,
              계약상 이를 공개된 목적으로만 보호하고 사용할 의무가 있습니다.
            </p>
            <br />
            <p>
              <strong>정보 보안 방법</strong>
            </p>
            <p>
              개인 정보의 보안은 당사에게 중요합니다. 당사는 개인 정보의 분실, 도난, 무단 사용, 노출
              또는 검색으로부터 보호하기 위해 관리, 기술 및 물리적 보호를 유지합니다. 자세한 내용은
              당사의 보안 페이지를 확인하십시오.
            </p>
            <br />
            <p>
              보안 위반을 알게 되면 적절한 보호 조치를 취할 수 있도록 전자적으로(적용되는 법률에
              따라) 통지를 시도합니다. 예를 들어, 당사는 당사 사이트 또는 서비스의 다른 곳에 통지를
              게시하고 파일로 귀하의 이메일 주소로 이메일을 보낼 수 있습니다. 거주 지역에 따라 보안
              위반 통지를 서면으로 받을 수 있는 법적 권리가 있을 수 있습니다.
            </p>
            <br />
            <p>
              <strong>계정 삭제</strong>
            </p>
            <p>
              언제든지 계정을 삭제할 수 있습니다. 언제든지 ‘문의하기’를 통하여 요청을 전송하여
              삭제할 수 있습니다. 이메일로 계정을 소유하고 있다고 판단할 수 있는 충분한 식별 정보가
              필요할 수 있습니다.
            </p>
            <br />
            <p>
              <strong>계정을 삭제하면 다음을 삭제합니다:</strong>
            </p>
            <br />
            <p>
              개인 정보 (예: 이름, 아이디, 비밀번호, 사용자 이름, 암호, 이메일 주소, 휴대폰 번호 등)
            </p>
            <p>사용자가 작성한 모든 컨텐츠</p>
            <br />
            <p>
              당사는 우발적이거나 악의적인 파괴로부터 정보를 보호하는 방식으로 당사의 서비스를 유지
              관리하는 것을 목표로 합니다. 따라서 당사에 제공한 개인 정보를 업데이트하거나 당사
              서비스에서 삭제한 후에도 법적 의무로 인해 당사가 개인 정보를 더 오랜 기간 동안
              보관해야 하는 경우를 제외하고 최대 30일 동안 당사의 백업 파일, 아카이브 및 서버 로그에
              개인 정보가 보관될 수 있습니다.
            </p>
            <br />
            <p>
              ​계정을 삭제해도 검색 엔진 인덱스(예: Google) 및 네트워크 캐시에서 서비스에 게시한
              모든 컨텐츠가 완전히 제거되지 않을 수 있습니다. 이러한 엔티티의 동작을 제어할 수
              없습니다.
            </p>
            <br />
            <p>
              <strong>귀하의 권리</strong>
            </p>
            <p>
              귀하는 당사가 귀하에 대해 처리하는 개인 정보의 수정 및/또는 삭제를 요청할 수 있습니다.
              또한 귀하는 귀하가 이전에 당사에 동의한 경우에도 언제든지 마케팅 메시지 또는 제품
              알림에서 제외할 수 있습니다. 등록 사용자인 경우 서비스에 로그인하고 귀하의 계정 설정
              페이지를 확인하여 귀하의 계정과 관련된 대부분의 정보에 액세스하고 업데이트할 수
              있습니다.
            </p>
            <br />
            <p>
              <strong>언제든지 개인정보 처리 동의를 철회할 수 있는 권리</strong>
            </p>
            <p>잘못되었거나 불법적으로 처리된 개인 데이터를 잊거나 삭제 또는 차단할 권리</p>
            <p>특정 유형의 개인 정보 처리를 제한하거나 이의를 제기할 권리</p>
            <p>잘못된 또는 불완전한 개인 정보의 수정을 요구할 권리</p>
            <p>
              구조화된 기계 판독 가능 형식의 개인 정보 사본을 입수하고 요청 시 개인 데이터를 다른
              데이터 컨트롤러에 전송할 수 있는 권리
            </p>
            <br />
            <p>
              당사는 직접적인 마케팅 목적으로 귀하의 개인 정볼르 다른 제3자와 공유하지 않았습니다.
              서면으로 필요한 경우 본 정책의 후반부에 제공된 정보를 통해 당사에 연락할 수 있습니다.
            </p>
            <br />
            <p>
              <strong className="font-bold">개인정보처리방침</strong>
            </p>
            <br />
            <p>
              에듀지원 주식회사(이하 “회사”라고 함)는 개인정보보호법, 정보통신망 이용촉진 및
              정보보호에 관한 법률 등 관련 법령상의 개인정보보호 규정을 준수하며, 관련 법령에 의거한
              개인정보처리방침 등을 정하여 고객, 임직원, 홈페이지 이용자 등 정보주체의 권익 보호에
              최선을 다하고 있습니다. 회사는 개인정보처리방침 등을 통하여 정보주체가 제공하는
              개인정보가 어떠한 용도와 방식으로 처리되고 있으며, 개인정보보호를 위해 어떠한 조치가
              취해지고 있는지 알려 드립니다. 회사는 개인정보처리방침 등을 개정하는 경우
              www.dahandin.com(이하 “다했니”라고 함) 로그인 시 노출되는 공지사항(또는 개별공지)을
              통해 공지할 것입니다.
            </p>
            <p>
              회사가 공개하는 개인정보 관련 방침은, 회사가 처리하는 모든 정보주체의 개인정보 보호에
              관한 {"<개인정보처리방침>"}, 개인영상정보 보호에 관한
              {"<영상정보처리기기운영·관리방침>"}의 두 가지로 구성되어 있습니다.
            </p>
            <br />
            <p>
              <strong className="font-bold">1. 개인정보처리방침</strong>
            </p>
            <br />
            <p>01. 총칙</p>
            <p>
              - “개인정보”란 살아 있는 개인에 관한 정보로서 성명, 주소 및 영상 등을 통하여 개인을
              알아볼 수 있는 정보(해당 정보만으로는 특정개인을 알아볼 수 없더라도 다른 정보와 쉽게
              결합하여 알아볼 수 있는 것을 포함한다)를 말합니다.
            </p>
            <p>
              - “정보주체”란 처리되는 정보에 의하여 알아볼 수 있는 사람으로서 그 정보의 주체가 되는
              사람을 말합니다.
            </p>
            <p>
              - 회사는 개인정보처리방침을 홈페이지별로 첫 화면에 공개함으로써 언제나 쉽게 확인할 수
              있도록 조치하고 있으며, 개인정보처리방침을 개정하는 경우 웹사이트 공지사항(또는
              개별공지)을 통하여 공지할 것입니다.
            </p>
            <br />
            <p>02. 개인정보의 수집/처리 항목 및 목적</p>
            <p>
              회사는 패밀리 사이트 회원가입 및 개별 서비스 이용 과정에서 필요한 최소한의 정보를
              수집합니다. 법령의 규정 또는 정보주체의 동의 없이는 정보주체의 사생활을 현저히 침해할
              우려가 있는 민감정보, 개인을 고유하게 구별하기 위하여 부여된 고유식별정보를 처리하지
              않습니다.
            </p>
            <br />
            <p>가. 수집 및 처리 항목</p>
            <p> ① 필수정보</p>
            <p style={{ textDecoration: "underline" }}>
              ㄱ. 다했니 가입시 : 이름, 이메일, 휴대폰번호, 아이디, 비밀번호, 카카오의 이용자 고유
              식별자 ( SNS서비스사로부터 제공받은 이용자 고유 식별자 정보는 SNS 로그인 연동시에만
              이용하며, 진행하지 않는 경우 즉시 파기합니다. 또한, 당사의 SNS 로그인 연동은 고객이
              직접 동의를 수행한 경우에 한하여 이용자 고유 식별자 정보를 보관합니다.
            </p>
            <br />
            <p> ② 선택정보</p>
            <p>
              ㄱ. 주소, 광고성 문자 메시지 수신여부, 광고성 이메일 수신여부, 개인정보 보유 기간 설정
              정보
            </p>
            <p>
              ㄴ. 선택정보를 입력하지 않은 경우에도 기본 서비스 이용에 제한은 없으며 이용자의 기본적
              인권 침해의 우려가 있는 민감한 개인 정보(인종, 사상 및 신조, 정치적 성향이나 범죄기록,
              의료정보 등)는 수집하지 않습니다.
            </p>
            <p>
              ㄷ. 다했니의 기능을 사용하는 경우에 한하여 텍스트, 이미지, 동영상, 음성, 각종 형식의
              파일 등 멀티미디어 자료가 수집됩니다. 수집된 정보는 AWS 에 위탁 및 수탁됩니다.
            </p>
            <p>
              ㄹ. 다했니를 통하여 등록하는 ‘학급명, 학생번호, 학생명, 학생 연락처’ 는 모두 사용자가
              임의로 기입할 수 있는 내용이나 기입하는 내용에 따라 개인이 식별될 수 있음으로 가명
              등의 이용을 권장합니다.
            </p>
            <br />
            <p> ③ 개별 서비스 이용 시</p>
            <p>
              ㄱ. 두클래스x다했니 : 학급명, 학생명 등의 정보는 이용의 편의를 위해 다했니에서
              이용자가 설정한 정보를 다했니로부터 제공받아 표시하는 것으로 두클래스에서는 이를
              수집하지 않습니다.
            </p>
            <br />
            <p> ④ 유료 서비스 이용 시</p>
            <p>ㄱ. 신용카드 정보, 은행계좌 정보, 결제기록 등의 정보가 수집될 수 있습니다.</p>
            <br />
            <p>⑤ 서비스 이용과정에서 아래와 같은 정보들이 자동으로 생성되어 수집될 수 있습니다.</p>
            <p>
              ㄱ. 이용자의 브라우저의 종류 및 OS, 검색어, IP Address, 쿠키, 방문 일시, 서비스 이용
              기록, 불량 이용 기록
            </p>
            <br />
            <p> 나. 처리 목적</p>
            <p> {"<고객 관련>"}</p>
            <p> ① 회원 관리, 불만처리 등 민원처리</p>
            <p> ② 온라인 시장조사 또는 여론 조사</p>
            <p> ③ 마케팅 또는 광고</p>
            <p> ④ 서비스 제공 및 요금정산</p>
            <br />
            <br />
            <p>03. 개인정보의 처리 및 보유 기간</p>
            <p>
              원칙적으로, 개인정보의 수집 및 이용 목적이 달성된 후에는 해당 정보를 지체없이
              파기합니다. 다만, 다음의 정보에 대해서는 아래의 이유로 명시한 기간 동안 보유합니다.
            </p>
            <br />
            <p>가. 계약 도는 청약철회 등에 관한 기록</p>
            <p> ① 보존 이유 : 전자상거래 등에서의 소비자보호에 관한 법률</p>
            <p> ② 보유 기간 : 5년</p>
            <br />
            <p>나. 대금결제 및 재화 등의 공급에 관한 기록</p>
            <p> ① 보존 이유 : 보유 이유 : 전자상거래 등에서의 소비자보호에 관한 법률</p>
            <p> ② 보유 기간 : 5년</p>
            <br />
            <p>다. 소비자의 불만 또는 분쟁처리에 관한 기록</p>
            <p> ① 보유 이유 : 전자상거래 등에서의 소비자보호에 관한 법률</p>
            <p> ② 보유 기간 : 3년</p>
            <br />
            <p>라. 방문에 관한 기록</p>
            <p> ① 보유 이유 : 통신비밀보호법</p>
            <p> ② 보유 기간 : 3년</p>
            <br />
            <p>04. 개인정보의 파기 절차 및 방법</p>
            <br />
            <p>가. 파기 절차</p>
            <p>
              회사는 수집한 개인정보의 처리목적이 달성되거나 그 보유기간이 종료되는 경우 정보주체의
              동의, 이용약관, 관련 법령에 따라 보관이 필요한 경우를 제외하고 해당 정보를 지체 없이
              파기합니다.
            </p>
            <br />
            <p>나. 파기 방법</p>
            <p>
              ① 전자적 파일형태로 저장된 개인정보는 기록을 재생할 수 없는 기술적 방법을 사용하여
              삭제합니다.
            </p>
            <p>② 종이에 출력된 개인정보는 분쇄기로 분쇄하거나 소각을 통하여 파기합니다.</p>
            <br />
            <p>05. 개인정보의 제3자 제공</p>
            <p>
              회사는 원칙적으로 “02. 개인정보의 처리 항목 및 목적”에서 고지한 목적 범위 내에서
              개인정보를 이용하며, 동 범위를 초과하여 개인정보를 이용하거나 제3자에게 제공·공개하지
              않습니다. 제공에 대한 동의를 거부하시고자 하는 경우 회원 정보 변경을 통해 직접 탈퇴
              진행 또는 고객센터로 요청할 수 있습니다. 단, 동의 거부에 따른 서비스 사용에 제한이
              있을 수 있습니다.
            </p>
            <br />
            <p>가. 위탁 처리 </p>
            <p>
              고객과의 계약을 이행하기 위해 이용자의 개인정보를 위탁 처리할 경우 반드시 사전에
              위탁처리 업체명과 업무위탁 목적 등에 대해 상세하게 고지합니다. 위탁 처리는 고객과의
              계약을 이행하기 위해 반드시 위탁업체에 개인정보를 제공해야 하며,
              ‘전자상거래소비자보호법’ 등에 의한 근거가 있습니다. 따라서 위탁업체에 개인정보를
              제공하는 것을 거부하는 경우에는 당사의 서비스를 제공받지 못할 수 있습니다. 단, 재화
              또는 용역을 홍보하거나 판매를 권유하는 업무를 위탁하는 경우에는 서면, 전자우편,
              모사전송, 휴대전화 문자전송 등의 방법으로 위탁하는 업무의 내용과 수탁자를
              알려드립니다.
            </p>
            <br />
            <p>나. 제3자 제공 업체</p>
            <p>
              보다 나은 서비스 제공을 위하여 이용자의 개인정보에 대한 동의를 구한 후 제휴사에게
              제공하거나 또는 제휴사와 공유할 수 있습니다. 개인정보를 제공하거나 공유할 경우에는
              사전에 이용자에게 제휴사가 누구인지, 제공 또는 공유되는 개인정보항목이 무엇인지, 왜
              그러한 개인정보가 제공되거나 공유되어야 하는지 등의 동의절차를 진행하며, 이용자께서
              동의하지 않는 경우에는 개인정보를 제공하지 않습니다.
            </p>
            <p>① 제 3자 제공업체 리스트</p>
            <p>&nbsp; ㄱ. 업체명 : 동아출판㈜</p>
            <p>&nbsp; · 개인정보항목 : 학급명, 학생명</p>
            <p>&nbsp; · 제공 목적 : 두클래스 서비스 연동 (‘과제 생성’ 기능 사용)</p>
            <p>
              &nbsp; · 보유 및 이용기간 : 두클래스에서 상기 개인정보가 노출은 되나, 보유 및 저장되지
              않음
            </p>
            <p style={{ textDecoration: "underline" }}>
              &nbsp; (두클래스 연동 시 별도의 추가 동의를 구하며, 미동의 시엔 사용에 제한이 있을 수
              있습니다. 단, 연동 후 전달되는 개인정보는 상기 표기된 이용 목적 외 사용되지 않습니다.)
            </p>
            <br />
            <p>다. 다음의 경우 예외로 합니다.</p>
            <p>- 정보주체로부터 별도의 동의를 받는 경우</p>
            <p>- 다른 법률에 특별한 규정이 있는 경우</p>
            <p>
              - 정보주체 또는 그 법정대리인이 의사표시를 할 수 없는 상태에 있거나 주소불명 등으로
              사전 동의를 받을 수 없는 경우로서 명백히 정보주체 또는 제3자의 급박한 생명, 신체,
              재산의 이익을 위하여 필요하다고 인정되는 경우
            </p>
            <p>
              - 통계작성 및 학술연구 등의 목적을 의하여 필요한 경우로서 특정 개인을 알아볼 수 없는
              형태로 개인정보를 제공하는 경우
            </p>
            <br />
            <p>06. 개인정보처리의 위탁 및 수탁</p>
            <p>회사는 정보주체의 동의 없이 개인정보의 처리를 외부 업체에 위탁하지 않습니다.</p>
            <p>
              회사는 아래와 같이 개인정보의 처리를 위탁하고 있으며, 관련 법령에 따라 위탁계약 시
              개인정보가 안전하게 관리될 수 있도록 필요한 사항을 규정하고 있습니다.
            </p>
            <p>
              개인정보처리 위탁에 대한 동의를 거부하시고자 하는 경우 회원 정보 변경을 통해 직접 탈퇴
              진행 또는 고객센터로 요청할 수 있습니다. 단, 동의 거부에 따른 서비스 사용에 제한이
              있을 수 있습니다.
            </p>
            <br />
            <p>가. 수탁자: ㈜카카오</p>
            <p>
              &nbsp; ① 공유 정보 범위 : 이메일, 비밀번호, 이름(닉네임), 카카오톡 전화번호(카카오톡
              이용자의 경우에 한함), 연락처 (해당 업체에서 이미 보유하고 있는 개인정보이기 때문에
              별도로 저장하지 않음)
            </p>
            <p>&nbsp; ② 위탁업무내용 : 본인인증(회원가입 등)</p>
            <p>&nbsp; ③ 위탁기간 : 회원 탈퇴 시 혹은 위탁계약 종료시까지</p>
            <p>나. 수탁자: Amazon Web Services, Inc.</p>
            <p>&nbsp; ① 이전 목적 : Cloud 서비스 제공</p>
            <p>
              &nbsp; ② 이전 항목 : 다했니 서비스를 통해 처리되는 정보로 본 정책 ‘1. - 02. 개인정보의
              수집/처리 항목 및 목적 ’ 에서 확인하실 수 있습니다.
            </p>
            <p>
              &nbsp; ③ 이전 국가 : AWS 한국, 글로벌에 저장되며, 서비스 이용 시점에 네트워크를 통하여
              전송됩니다.
            </p>
            <p>&nbsp; ④ 정보관리책임자의 연락처 : aws-korea-privacy@amazon.com</p>
            <br />
            <p>07. 정보주체의 권리 및 그 행사방법</p>
            <br />
            <p>
              가. 모든 정보주체는 회사가 처리하는 자신의 개인정보에 대한 열람, 정정, 상제 및 처리
              정리를 요구할 수 있습니다. 이 때 권리 행사는 정보 주체의 법정대리인이나 위임을 받은 자
              등 대리인을 통하여 하실 수 있습니다. (이 경우 개인정보 보호법 시행규칙 별지 제 11호
              서식에 따른 위임장을 제출하셔야 합니다.) 다만, 아래 사항에 해당하는 경우 회사는 위와
              같은 요구를 거절하거나 제한할 수 있습니다.
            </p>
            <p>&nbsp; ① 법률에 특별한 규정이 있거나 법령상 의무를 준수하기 위하여 불가피한 경우</p>
            <p>
              &nbsp; ② 다른 사람의 생명·신체를 해할 우려가 있거나 다른 사람의 재산과 그 밖의 이익을
              부당하게 침해할 우려가 있는 경우
            </p>
            <p>
              &nbsp; ③ 개인정보를 처리하지 아니하면 정보주체와 약정한 서비스를 제공하지 못하는 등
              계약의 이행이 곤란한 경우로서 정보주체가 그 계약의 해지 의사를 명확하게 밝히지 아니한
              경우
            </p>
            <br />
            <p>나. 권리행사 방법 및 절차</p>
            <p>
              &nbsp; ① 개인정보의 열람 등을 원하는 정보주체는 열람·정정·삭제·처리정지 청구서를 서면,
              전화, 전자우편, 팩스 등의 방법으로 개인정보 담당부서에 제출하실 수 있습니다.
              (담당부서는 “09. 개인정보에 관한 민원 서비스” 참조)
            </p>
            <p>
              &nbsp; ② 회사는 정당한 사유가 없는 한 10일 이내에 해당 조치를 취하고, 거절 또는 제한의
              사유가 있는 경우에는 5일 이내에 제한·거절의 사유 및 이의 제기 방법을 알려드립니다. 이
              경우 회사는 해당 기간 동안 당해 개인정보를 이용하거나 제공하지 않습니다.
            </p>
            <p>
              &nbsp; ③ 회사는 정보주체 또는 그의 대리인이 개인정보의 열람 등을 청구하는 때에는
              신분증명서, 공인전자서명 등을 확인하여 정보주체 본인 또는 대리인 여부를 확인할 수
              있습니다.
            </p>
            <br />
            <p>08. 개인정보의 기술적/관리적/물리적 보호 대책</p>
            <p>
              회사는 개인정보를 취급함에 있어 개인정보가 분실, 도난, 누출 변조 또는 훼손되지 않도록
              아래와 같은 안전성 확보 조치를 취하고 있습니다.
            </p>
            <br />
            <p>가. 기술적 조치</p>
            <p>
              ① 개인정보의 안전한 저장 및 전송 등을 위하여 법령이 정한 기준을 준수하고 있습니다.
            </p>
            <p>
              ② 회사는 백신프로그램을 이용하여 컴퓨터바이러스에 의한 피해를 방지하기 위한 조치를
              취하고 있습니다. 백신프로그램은 주기적으로 업데이트되며 갑작스런 바이러스가 출현할
              경우 백신이 나오는 즉시 이를 제공함으로써 개인정보가 침해되는 것을 방지하고 있습니다.
            </p>
            <p>
              ③ 해킹 등 외부침입에 대비하여 각 서버마다 침입차단시스템 및 취약점 분석 시스템 등을
              이용하여 보안에 만전을 기하고 있습니다.
            </p>
            <br />
            <p>나. 관리적 조치</p>
            <p>
              ① 회사는 정보주체를 직접 상대로 하여 영업 및 마케팅 업무를 수행하는 자,
              개인정보관리업무를 수행하는 자, 기타 업무상 개인정보의 취급이 불가피한 자 등으로
              개인정보에 대한 접근권한을 제한하고 있습니다.
            </p>
            <p>
              ② 개인정보를 취급하는 직원을 대상으로 개인정보보호에 관한 정기적인 사내 교육 및 외부
              위탁교육을 실시하고 있으며, 입사 및 퇴사 후 개인정보보호에 관한 법령을 준수하도록
              철저히 관리·감독하고 있습니다.
            </p>
            <br />
            <p>다. 물리적 조치</p>
            <p>
              ① 개인정보와 개인정보처리시스템의 안전한 보관을 위하여 물리적 잠금장치 등의 물리적
              접근방지를 위한 보호조치를 취하고 있습니다.
            </p>
            <p>② 전산실 및 자료 보관실 등을 특별 보호구역으로 설정하여 출입을 통제하고 있습니다.</p>
            <br />
            <p>09. 개인정보에 관한 민원 서비스</p>
            <p>
              회사는 개인정보를 보호하고 개인정보와 관련한 불만을 처리하기 위하여 아래와 같이 관련
              부서 및 개인정보 보호책임자를 지정하고 있습니다.
            </p>
            <br />
            <p>가. 개인정보 보호책임자</p>
            <p>&nbsp; 이름: 최석모</p>
            <p>&nbsp; 전화번호: 010-2855-7582</p>
            <p>&nbsp; 이메일: edujiwon@naver.com</p>
            <p>&nbsp; 전화번호: (월~금) 09:00 ~ 18:00, (토~일) 휴무</p>
            <br />
            <p>
              나. 기타 개인정보침해에 대한 신고나 상담이 필요하신 경우에는 아래 기관에 문의하시기
              바랍니다.
            </p>
            <p>&nbsp; 개인정보 분쟁조정위원회 : (국번없이) 1833-6972 (www.kopico.go.kr)</p>
            <p>&nbsp; 개인정보 침해신고센터 : (국번없이) 118 (privacy.kisa.or.kr)</p>
            <p>&nbsp; 대검찰청 사이버범죄수사단 : 02-3480-3571 (www.spo.go.kr)</p>
            <p>&nbsp; 경찰청 사이버안전국 : 182 (http://www.police.go.kr)</p>
            <br />
            <br />
            <p>10. 개인정보 자동 수집 장치의 설치, 운영 및 그 거부에 관한 사항</p>
            <br />
            <p>
              가. 회사는 이용자에게 편의를 제공하기 위해 이용정보를 저장하고 수시로 불러오는
              ‘쿠키(cookie)’를 사용합니다.
            </p>
            <p>
              나. 쿠키는 웹사이트를 운영하는데 이용되는 서버(http)가 이용자의 컴퓨터 브라우저에게
              보내는 소량의 정보이며 이용자들의 PC 컴퓨터내의 하드디스크에 저장되기도 합니다.
            </p>
            <br />
            <p>
              ① 쿠키의 사용목적: 이용자가 방문한 각 서비스와 웹 사이트들에서 자동 로그인, SSO 연동과
              같이 이용자에게 편의를 제공하는 기능을 위해 사용됩니다.
            </p>
            <p>
              ② 쿠키의 설치∙운영 및 거부 : 웹브라우저 상단의 도구{">"}인터넷 옵션{">"}개인정보
              메뉴의 옵션 설정을 통해 쿠키 저장을 거부 할 수 있습니다.
            </p>
            <p>
              ③ 단, 이용자가 쿠키 설치를 거부하였을 경우 일부 서비스 제공에 어려움이 있을 수
              있습니다.
            </p>
            <br />
            <p>11. 공지 의무</p>
            <p>
              현 개인정보처리방침의 내용 추가, 삭제 및 수정이 있을 시에는 개정 최소 7일 전부터
              홈페이지의 ‘공지사항’을 통해 고지할 것입니다.
            </p>
            <br />
            <p>
              이 개인정보 처리방침은 2023년 <span style={{ color: "blue" }}>8월 8일부터</span>{" "}
              적용됩니다.
            </p>
            <p>
              변경 전 개인정보 처리방침은 ‘www.blog.naver.com/dahandin’에서 확인하실 수 있습니다
            </p>
            <br />
            <p>
              ▣ 공고일자 : 2023년
              <span style={{ color: "blue" }}> 8월 1일</span>
            </p>
            <p>
              ▣ 시행일자 : 2023년
              <span style={{ color: "blue" }}> 8월 8일</span>
            </p>
          </div>

          <div className="box-agree">
            <button
              onClick={() => setModal(false)}
              className="mb-26 white font-16 font-500 cancel-button mr-10"
            >
              {t(`Cancel`)}
            </button>
            <button
              onClick={() => {
                setAgree1(true);
                setAgree2(true);
                setModal(false);
              }}
              className="font-16 font-500 sign-up-button"
            >
              모두 동의
            </button>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="container text-center pb-40">
      <div className="font-60 mt-60 mb-63">
        <img src={logoTop} className="login_logo_top ml-7" alt="logoTop" />
        <br />
        <img src={logoBottom} className="login_logo_bottom ml-30" alt="logoBottom" />
      </div>
      <div className="sign-up-bottom text-left">
        <p className="margin-0 sign__up__text font-700 font-30">
          {/* 회원가입 */}
          {t(`Sign up`)}
        </p>
        <div style={{ marginBottom: 20 }} className="card-text-red font-12">
          <p>
            {/* 개인정보 관리가 용이한 카카오톡 간편 로그인(계정 연동)을 추천드립니다. */}
            {t(`We recommend KakaoTalk Simple Login...`)}
          </p>
          <p>
            {/* 아이디 공유가 필요한 특수한 경우(학원 강사 등)의 경우에만 추천드립니다. */}
            {t(`It is only recommended for special cases...`)}
          </p>
        </div>
        <p className="margin-0 font-500 font-15 mb-12">
          {/* 이름 */}
          {t(`Name`)}
        </p>
        <input
          onChange={(e) => setName(e.target.value)}
          className="mb-12 font-18 pl-36 pr-36 id-input"
          // placeholder="이름을 입력해주세요."
          placeholder={t(`Please enter a name`)}
          value={name}
        />
        <p className="margin-0 font-500 font-15 mb-12">
          {/* 아이디 */}
          {t(`ID`)}
        </p>
        <input
          onChange={(e) => setId(e.target.value.trim())}
          className="mb-12 font-18 pl-36 pr-36 id-input"
          // placeholder="아이디를 입력하세요."
          placeholder={t(`Please enter your ID`)}
          value={id}
        />
        <span onClick={handleCheckSame} className="position-r check-same-name cursor">
          중복확인
        </span>
        <p className="margin-0 font-500 font-15 mb-12 email">
          {/* 이메일 */}
          {t(`Email`)}
        </p>
        {/* <form> */}
        <div className="position-r">
          <input
            type="email"
            onChange={(e: any) => handleEmail(e)}
            className="mb-12 font-18 pl-36 pr-36 id-input email__input"
            // placeholder="이메일을 입력하세요."
            placeholder={t(`Please enter your email`)}
            value={email}
          />
          <span onClick={handleCheckEmail} className="position-a check-same-email cursor">
            {/* 중복확인 */}
            {t(`Check for duplicates`)}
          </span>
        </div>
        {!verifyEmail() && (
          <p className="mt-m4 font-500 dark-red font-15">
            {/* 이메일형식을 확인해주세요. */}
            {t(`Please check the email format`)}
          </p>
        )}
        {/* </form> */}
        {/* <p className="margin-0 font-500 font-15 mb-12">전화번호</p>
        <input
          type="text"
          onChange={(e) => setPhone(e.target.value)}
          className="mb-12 font-18 pl-36 pr-36 pw-input"
          placeholder="전화번호를 입력하세요."
          value={phone}
        /> */}
        <p className="margin-0 font-500 font-15 mb-12">
          {/* 비밀번호 */}
          {t(`Password`)}
        </p>
        <input
          type="password"
          onChange={(e) => setPw(e.target.value)}
          className="mb-12 font-18 pl-36 pr-36 pw-input"
          // placeholder="비밀번호를 입력하세요."
          placeholder={t(`Please enter your password`)}
          value={pw}
        />
        <br />
        <p className="margin-0 font-500 font-15 mb-12">
          {/* 비밀번호 확인 */}
          {t(`Confirm password`)}
        </p>
        <input
          type="password"
          onChange={(e) => setPwCheck(e.target.value)}
          className="mb-12 font-18 pl-36 pr-36 pw-input"
          // placeholder="비밀번호를 다시 입력하세요."
          placeholder={t(`Please enter your password again`)}
          value={pwCheck}
        />

        <p onClick={() => setModal(true)} className="margin-0 font-15 mb-12 cursor">
          <span className="mr-2">
            <input type="checkbox" checked={agree1} />
          </span>
          {/* &nbsp;&nbsp;회원가입 약관 동의 (미동의시 가입불가) */}
          &nbsp;&nbsp;{t(`Agree to the Terms of Membership...`)}
        </p>
        <p onClick={() => setModal(true)} className="margin-0 font-15 mb-33 cursor">
          <span>
            <input type="checkbox" checked={agree2} />
          </span>
          {/* &nbsp;&nbsp;개인정보 수집 동의 (미동의시 가입불가) */}
          &nbsp;&nbsp;{t(`Personal Information Collection Agreement...`)}
        </p>

        <button
          onClick={handleSubmit}
          className="mb-26 font-16 font-500 sign-up-button mr-40 mt-20"
        >
          {/* 회원가입 */}
          {t(`Sign up`)}
        </button>
        <Link to="/login">
          <button className="mb-26 white font-16 font-500 cancel-button">
            {/* 취소 */}
            {t(`Cancel`)}
          </button>
        </Link>
      </div>

      {modal && <Modal />}
    </div>
  );
};

export default SignUp;
